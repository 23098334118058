<div class="w-100 h-100">
    <div class="row" style="padding-top: 25px;padding-bottom: 25px;">
        <div class="col-md-12">
            <div class="form-row justify-content-between align-items-baseline">
                <div class="col-auto">
                    <label class="header_text mb-3">
                        {{$t('shared.userInfo')}}
                    </label>
                </div>
                <div class="col-auto">
                    <router-link :to="'/UserManagement/UserRegistration/Edit/' + id" tag="button" class="btn btn-primary">{{$t('shared.editInfo')}}</router-link>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="card card_layout">
                <div class="card-body py-5 px-5">
                    <div class="row mx-3">
                        <div class="col-md-3 col-sm-4">
                            <label class="user_info_header">{{$t('shared.userName')}}</label>
                            <h4 class="user_info_txt">{{user.username}}</h4>
                        </div>
                        <div class="col-md-3 col-sm-4">
                            <label class="user_info_header">{{$t('shared.userEmail')}}</label>
                            <h4 class="user_info_txt">{{user.email}}</h4>
                        </div>
                        <div class="col-md-3 col-sm-4">
                            <label class="user_info_header">{{$t('shared.phoneNumber')}}</label>
                            <h4 class="user_info_txt">{{user.mobileNumber}}</h4>
                        </div>
                        <div class="col-md-3 col-sm-4" v-if="user.roleId == '2' || user.roleId == '5'">
                            <label class="user_info_header">{{$t('shared.providerName')}}</label>
                            <h4 class="user_info_txt">{{user.providerName}}</h4>
                        </div>
                        <div class="col-md-3 col-sm-4" v-if="user.roleId == '6'">
                            <label class="user_info_header">{{$t('shared.operatorName')}}</label>
                            <h4 class="user_info_txt">{{user.operatorName}}</h4>
                        </div>
                        <div class="col-md-3 col-sm-4">
                            <label class="user_info_header">{{$t('dashboardPage.status')}}</label>
                            <h4 class="user_info_txt" v-if="!user.isLocked">{{$t('shared.unLock')}}</h4>
                            <h4 class="user_info_txt" v-if="user.isLocked">{{$t('shared.lock')}}</h4>
                        </div>
                        <div class="col-md-12 mt-5">
                            <div class="form-row justify-content-end align-items-center">
                                <div class="col-auto mx-3">
                                    <router-link to="/UserManagement" tag="a" class="link_secondary router-link-active">{{$t('shared.back')}}</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

