<div class="w-100 h-100">
    <div class="auto_animation w-100 h-100" v-show="elementVisible">
        <div class="logo_text d-flex">
            <img :src="require( `../img/${logoSrc}.svg`)" class="px-5" width="500" />
        </div>
    </div>
    <div class="main_page w-100 h-100" v-show="!elementVisible">
        <div class="main_box">
            <div class="main_header_box d-flex justify-content-between align-items-center">
                <div class="logo mb-2">
                    <img :src="require( `../img/${logoTextSrc}.svg`)" width="150" />
                </div>
                <div class="link">
                    <a class="language" @click="changeLanguage">
                        {{$t('shared.language')}}
                    </a>
                </div>
            </div>
            <div class="card card_login" v-if="logInForm">
                <div class="card-header card_header_login d-flex justify-content-between align-items-baseline">
                    <label class="login_header">
                        {{$t('loginPage.loginHeader')}}
                    </label>
                    <router-link to="/MarketerSignUp" tag="a" class="link_login text-right">{{$t('loginPage.AddMarketerAccount')}}</router-link>
                </div>
                <div class="card-body p-0">
                    <label class="w-100 text-center text_body pt-3">
                        {{$t('loginPage.loginNote1')}}
                    </label>
                    <div class="row row_input py-3">
                        <div class="col-md-12">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <img :src="require('../img/User.svg')" />
                                    </span>
                                </div>
                                <input type="text" class="form-control border-left-0" v-model="userName" :placeholder="$t('loginPage.userName')" />
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><img :src="require('../img/Lock.svg')" /></span>
                                </div>
                                <input class="form-control border-right-0 border-left-0" v-model="password" v-bind:type="[showPassword ? 'text' : 'password']" :placeholder="$t('loginPage.password')" />
                                <div class="input-group-append">
                                    <span class="input-group-text right">
                                        <i class="fas" :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }" @click="showPassword = !showPassword"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row_button pb-3">
                        <div class="form-row justify-content-center align-items-baseline">
                            <div class="col-auto mr-3">





                                <a class="link_login" @click="showRestoreForm">
                                    {{$t('loginPage.canNotLogin')}}
                                </a>

                            </div>
                            <button class="btn btn-primary mb-2" :disabled="!isValid" @click="login">{{$t('loginPage.login')}}</button>
                        </div>
                    </div>                   
                </div>
            </div>
            <div class="card card_login" v-if="otpForm">
                <div class="card-header card_header_login">
                    <label class="login_header">
                        {{$t('loginPage.loginHeader')}}
                    </label>
                </div>
                <div class="card-body">
                    <label class="w-100 text-center text_body">
                        {{$t('loginPage.enterOtp')}} <br />
                        {{$t('loginPage.OtpReminingTime')}}: {{ countDown }}
                    </label>
                    <div class="row row_input pb-4">
                        <div class="col-md-12">
                            <div class="form-group">
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control text-center" v-model="otp" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row_input row_button py-0">
                        <div class="form-row justify-content-between align-items-baseline">
                            <div class="col-auto">
                                <button class="btn btn-primary mb-2" :disabled="isResendValid" @click="resendOtp">{{$t('loginPage.resendOtp')}}</button>
                                <button class="btn btn-primary mb-2" :disabled="!isResendValid" @click="verfiyOTP">{{$t('loginPage.verfiyOTP')}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card card_login" v-if="changePasswordForm">
                <div class="card-header card_header_login">
                    <label class="login_header">
                        {{$t('loginPage.changePassword')}}
                    </label>
                </div>
                <div class="card-body">
                    <div class="row row_input pb-4">
                        <div class="col-md-12">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><img :src="require('../img/Lock.svg')" /></span>
                                </div>
                                <input class="form-control border-left-0" v-model="currentPassword" type="password" :placeholder="$t('loginPage.currentPassword')" />
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><img :src="require('../img/Lock.svg')" /></span>
                                </div>
                                <input class="form-control border-left-0" v-model="newPassword" type="password" :placeholder="$t('loginPage.newPassword')" />
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><img :src="require('../img/Lock.svg')" /></span>
                                </div>
                                <input class="form-control border-left-0" v-model="confirmPassword" type="password" :placeholder="$t('loginPage.confirmPassword')" />
                            </div>
                        </div>
                    </div>
                    <div class="row_input row_button py-0">
                        <div class="form-row justify-content-center align-items-baseline">
                            <div class="col-auto">
                                <button class="btn btn-primary mb-2" :disabled="!isValidChangePassword" @click="changePassword">{{$t('shared.save')}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card card_login" v-if="restoreForm">
                <div class="card-header card_header_login">
                    <label class="login_header">
                        {{$t('loginPage.restoreMyPassword')}}
                    </label>
                </div>
                <div class="card-body" v-if="checkRestoreForm">
                    <div class="row">
                        <div class="col-md-12 pb-5">
                            <label class="w-100 text_body">
                                {{$t('loginPage.restoreNote1')}}
                                <br />
                                <br />
                                {{$t('loginPage.restoreNote2')}}
                            </label>
                        </div>
                        <div class="col-md-12">
                            <div class="form-row justify-content-end align-items-baseline">
                                <div class="col-auto mx-3">
                                    <a class="link_login" @click="backLogin">
                                        {{$t('loginPage.backToLoginForm')}}
                                    </a>
                                </div>
                                <div class="col-auto">
                                    <button class="btn btn-primary mb-2" @click="showEmailForm">{{$t('loginPage.yesUnderstand')}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body" v-if="userNameForm">
                    <div class="row px-4">
                        <div class="col-md-12">
                            <label class="w-100 text_body">
                                {{$t('loginPage.pleaseInsertEmail')}}
                            </label>
                        </div>
                        <div class="col-md-12 pb-4">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <img :src="require('../img/User.svg')" />
                                    </span>
                                </div>
                                <input type="text" class="form-control border-left-0" v-model="userNameRe" :placeholder="$t('loginPage.userName')" />
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-row justify-content-end align-items-baseline">
                                <div class="col-auto">
                                    <button class="btn btn-primary mb-2" :disabled="!isValidResetPassword" @click="resetPassword">{{$t('loginPage.sendPassword')}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body" v-if="confermForm">
                    <div class="row px-4 text-center">
                        <div class="col-md-12 py-4">
                            <img :src="require('../img/check.svg')" />
                        </div>
                        <div class="col-md-12 pb-5">
                            <label class="w-100 text_body">
                                {{$t('loginPage.passwordResetedSuccessfully')}}
                                <br />
                                <a class="link_login" @click="backLogin">{{$t('loginPage.backToLogin')}}</a>
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="login_footer form-row justify-content-between align-items-center">
                <div class="col-auto">
                    <a>
                        {{$t('shared.privacy')}}
                    </a>
                </div>
                <div class="col-auto">
                    <a>
                        {{$t('shared.termsOfUse')}}
                    </a>
                </div>
                <div class="col-auto">
                    <a>
                        {{$t('shared.copyRight')}} {{currentYear}}
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="text-xs-center">

        <v-dialog v-model="updateShortCode" persistent width="554">
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                    {{$t('loginPage.updateShortCodeType')}}
                    <div class="close__dialog">
                        <i class="fas fa-times" @click="hideUpdateShortCodeDialog"></i>
                    </div>
                </v-card-title>


                <v-card-actions>
                    <v-spacer></v-spacer>
                    <div class="form-row justify-content-end align-items-center">
                        <div class="col-auto mx-3">
                            <a class="link_login" @click="hideUpdateShortCodeDialog">
                                {{$t('shared.Ok')}}
                            </a>
                        </div>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</div>



