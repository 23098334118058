<div class="w-100 h-100">
    <div class="main_page w-100 h-100">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="card card_layout">
                        <div class="card-header">
                            <label class="card_header_text">
                                {{$t('MarketerSignUp.RegisterANewAccount')}}
                            </label>
                        </div>
                        <div class="card-body p-0 ">
                            <div class="row px-5 pt-5">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>{{$t('shared.name')}}</label>
                                        <div class="input-group mb-3">
                                            <input class="form-control" type="text" v-model="model.name" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>{{$t('MarketerSignUp.commercialRegistrationNumber')}}</label>
                                        <div class="input-group mb-3">
                                            <input class="form-control" v-model="model.crNumber" type="text" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>{{$t('MarketerSignUp.Country')}}</label>
                                        <div class="input-group mb-3">
                                            <input class="form-control" v-model="model.country" type="text" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>{{$t('MarketerSignUp.City')}}</label>
                                        <div class="input-group mb-3">
                                            <input class="form-control" v-model="model.city" type="text" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>{{$t('MarketerSignUp.Region')}}</label>
                                        <div class="input-group mb-3">
                                            <input class="form-control" v-model="model.district" type="text" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>{{$t('MarketerSignUp.StreetName')}}</label>
                                        <div class="input-group mb-3">
                                            <input class="form-control"  v-model="model.streetName" type="text" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>{{$t('MarketerSignUp.BuildingNumber')}}</label>
                                        <div class="input-group mb-3">
                                            <input class="form-control" v-model="model.buildingNumber" type="text" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>{{$t('MarketerSignUp.PostalCode')}}</label>
                                        <div class="input-group mb-3">
                                            <input class="form-control" v-model="model.postalCode" type="text" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>{{$t('MarketerSignUp.TaxNumber')}}</label>
                                        <div class="input-group mb-3">
                                            <input class="form-control" v-model="model.taxNumber" type="text" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>{{$t('MarketerSignUp.NationalAddress')}}</label>
                                        <div class="input-group mb-3">
                                            <input class="form-control" v-model="model.nationalAddress" type="text" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row px-5 pb-5">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>{{$t('MarketerSignUp.UsernameOfTheMarketer')}}</label>
                                        <div class="input-group mb-3">
                                            <input class="form-control" v-model="model.username" type="text" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>{{$t('MarketerSignUp.MarketerEmail')}}</label>
                                        <div class="input-group mb-3">
                                            <input class="form-control" v-model="model.email" type="text" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>{{$t('MarketerSignUp.MarketerPhone')}}</label>
                                        <div class="input-group mb-3">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text gray">
                                                    +966
                                                </span>
                                            </div>
                                            <input class="form-control border-left-0" @change="isMobileNumberValid" @paste="isNumberFieldPaste($event)" maxlength="9" @keypress="isNumberField($event)" v-model="model.mobileNumber" type="text" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>{{$t('MarketerSignUp.password')}}</label>
                                        <div class="input-group mb-3">
                                            <input class="form-control" v-model="model.password" type="text" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>{{$t('MarketerSignUp.confirmPassword')}}</label>
                                        <div class="input-group mb-3">
                                            <input class="form-control" v-model="model.rePassword" type="text" />
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="card-footer">
                                <div class="form-row justify-content-between align-items-center">
                                    <div class="col-auto mx-3">
                                        <router-link to="/Login" tag="a" class="link_secondary">{{$t('MarketerSignUp.cancel')}}</router-link>
                                    </div>
                                    <div class="col-auto">
                                        <button class="btn btn-success px-5 py-2" @click="addMarketerRequest" :disabled="!isValid">
                                            {{$t('MarketerSignUp.apply')}}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="login_footer form-row justify-content-between align-items-center">
                        <div class="col-auto">
                            <a>
                                {{$t('shared.privacy')}}
                            </a>
                        </div>
                        <div class="col-auto">
                            <a>
                                {{$t('shared.termsOfUse')}}
                            </a>
                        </div>
                        <div class="col-auto">
                            <a>
                                {{$t('shared.copyRight')}} {{currentYear}}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

