import AxiosService from '@/Services/AxiosService';
import LoaderService from '@/Services/LoaderService';
import { Inject, Service } from 'vue-di-container';
import { HyperPayModel } from '../Payment/HyperPayModel';
import { AdvertiserRequestModel } from './AdvertiserRequestModel';


@Service()
export default class AddNewRequestService {
    @Inject(AxiosService) public axiosService!: AxiosService;
    @Inject(LoaderService) public loaderService!: LoaderService;

    public async post(model: any) {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/advertiser-request';
            const result = await this.axiosService.axiosInstance.post(url, model);
            this.loaderService.HideLoader();
            return result.data;
        } catch (error) {
            this.loaderService.HideLoader();
            return false;
        }
    }
    public async GetById(id: string): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/advertiser-request/GetById?id=' + id;
            const result = await this.axiosService.axiosInstance.get(url)
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
    public async put(model: any) {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/advertiser-request';
            const result = await this.axiosService.axiosInstance.put(url, model);
            this.loaderService.HideLoader();
            return result.data;
        } catch (error) {
            this.loaderService.HideLoader();
            return false;
        }
    }
    public async AdvertiserRequestPaymentTransaction(id: string): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/advertiser-request-payment-transaction?id='+id;
            const result = await this.axiosService.axiosInstance.post(url)
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
    public async GetHyperPayModel(amount: number, IsMada: boolean): Promise<any> {
        try {
            const url = '/HyperPay/GetHyperPayModel?Amount=' + amount + '&IsMada=' + IsMada;
            const result = await this.axiosService.axiosInstance.get(url);
            const Data = result.data;
            return Data;
        } catch (exception) {
            return new HyperPayModel();
        }
    }
    public async AddPaymentTransaction(id: string, checkOurId: string, isMada: boolean
        , sadadNumber: string | null, sadadNumberExpireDate: Date | null): Promise<any> {
        this.loaderService.ShowLoader();
        const ids = id;
        const model = {
            id: id,
            checkOurId: checkOurId,
            isMada: isMada,
            sadadNumber: sadadNumber,
            sadadNumberExpireDate: sadadNumberExpireDate
        };
        try {
            const url = window.$config.HostName + 'api/advertiser-request-payment-transaction/add-payment-transaction';
            const result = await this.axiosService.axiosInstance.post(url, model);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            return false;
        }
    }
    public async GetFileConten(id: string): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/advertiser-request/' + id + '/result';
            const result = await this.axiosService.axiosInstance.get(url)
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
}
