import AxiosService from '@/Services/AxiosService';
import LoaderService from '@/Services/LoaderService';
import { Inject, Service } from 'vue-di-container';
@Service()
export default class ReceivingAdvertisingCallsService {
    @Inject(AxiosService) public axiosService!: AxiosService;
    @Inject(LoaderService) public loaderService!: LoaderService;

    public async sendOtp(phoneNumber: string, recapchaResponse: string) {
        try {
            this.loaderService.ShowLoader();
            const url = 'api/login/call-registry-send-otp';
            const result = await this.axiosService.axiosInstance.post(url, {
                phoneNumber,
                recapchaResponse
            });
            this.loaderService.HideLoader();
            return result.data;
        } catch (error) {
            this.loaderService.HideLoader();
            return error;
        }
    }
    public async verfiyOTP(data: string, otpExpiresAt: string, otp: string, phone: string ) {
        try {
            const oTPVerificationData = {
                data,
                otpExpiresAt,
                otp,
                phone
            };
            this.loaderService.ShowLoader();
            const url = 'api/login/call-registry-verify-otp';
            const result = await this.axiosService.axiosInstance.post(url, oTPVerificationData);
            this.loaderService.HideLoader();
            return result.data;
        } catch (error) {
            return error;
        }
    }
    public async getallowCallRegistry(): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/CallRegistry';
            const result = await this.axiosService.axiosInstance.get(url);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
    public async resendOTP(data: string) {
        const resendOtTPData = {
            data,
        };
        try {
            this.loaderService.ShowLoader();
            const url = 'api/login/call-registry-resend-otp';
            const result = await this.axiosService.axiosInstance.post(url, resendOtTPData);
            this.loaderService.HideLoader();
            return result.data;
        } catch (error) {
            return error;
        }
    }
    public async allowCallRegistry(allow: boolean): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/CallRegistry?allow=' + allow;
            const result = await this.axiosService.axiosInstance.post(url)
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
}
