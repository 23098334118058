
<div class="main_page w-100 h-100">
    <div class="main_box">
        <div class="main_header_box d-flex justify-content-between align-items-center">
            <div class="logo mb-2">
                <img :src="require( `../img/JabberLogo.svg`)" width="150" />
            </div>
            <div class="link">
                <a class="language">
                    {{$t('shared.language')}}
                </a>
            </div>
        </div>
        <div class="card card_login">
            <div class="card-header card_header_login d-flex justify-content-between align-items-baseline">
                <label class="login_header">
                    <label class="card_header_text">
                        {{$t('ReceivingAdvertisingCalls.ReceivingAdvertisingCallsMsg')}}
                    </label>
                </label>
            </div>
            <div class="card-body p-0" v-if="EnterMobileNumber">
                <div class="row px-77 pt-lg-3 height-189">
                    <div class="col-md-12">
                        <div class="input-group ">
                            <label class="blod ">{{$t('ReceivingAdvertisingCalls.EnterMobileNumber')}}</label>
                            <div class="input-group">
                                <input class="form-control" type="text" v-model="phoneNumber" maxlength="10" @change="isMobileNumberValid" @keypress="isNumberField($event)" />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mt-3">
                        <vue-recaptcha ref="recaptcha" class="recaptcha" @verify="verifyRecaptcha" @expired="expiredRecaptcha" :sitekey="siteKey"></vue-recaptcha>
                    </div>

                </div>
                <div class="card-footer">
                    <div class="form-row justify-content-between align-items-center">
                        <div class="col-auto mx-4">

                        </div>
                        <div class="col-auto">
                            <button class="btn btn-success px-5 py-2 " :disabled="!isValid" @click="sendOtp"> {{$t('ReceivingAdvertisingCalls.Enter')}}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body p-0" v-if="isOTP">
                <div class="row px-77 py-1 py-lg-3 height-189">
                    <div class="col-md-12">
                        <div class="input-group ">
                            <label>{{$t('ReceivingAdvertisingCalls.VerificationCode')}}</label>
                            <div class="input-group">
                                <input class="form-control" type="text" v-model="otp" />
                            </div>
                        </div>
                    </div>
                    <label class="w-100 text-center text_body">
                        {{$t('loginPage.OtpReminingTime')}}: <b class="primary">{{ countDown }}</b>
                    </label>
                </div>
                <div class="card-footer">
                    <div class="form-row justify-content-between align-items-center">
                        <div class="col-auto mx-4">
                            <button class="btn btn-primary mb-2" :disabled="isResendValid" @click="resendOtp">{{$t('loginPage.resendOtp')}}</button>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-success px-5 py-2 " :disabled="!isValidOTP" @click="verfiyOTP"> {{$t('ReceivingAdvertisingCalls.send')}}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body p-0" v-if="isOptions">
                <div class="row px-77 w-100 text-center text_body  padding-top-54 height-189">
                    <div class="col-md-12">
                        <label class="w-100 text-center text_body">{{$t('ReceivingAdvertisingCalls.ReceivingAdvertisingCalls')}}</label>
                        <div class="form-row justify-content-center align-items-center">
                            <div class="col-auto">
                                <input type="radio" value="1" name="Options" v-model="Options" />
                                <label style="padding: 10px;">{{$t('ReceivingAdvertisingCalls.yes')}}</label>
                            </div>
                            <div class="col-auto">
                                <input type="radio" value="2" name="Options" v-model="Options" />
                                <label style="padding: 10px;">{{$t('ReceivingAdvertisingCalls.no')}}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="form-row justify-content-between align-items-center">
                        <div class="col-auto mx-4">

                        </div>
                        <div class="col-auto">
                            <button class="btn btn-success px-5 py-2" @click="allowCallRegistry"> {{$t('ReceivingAdvertisingCalls.send')}}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body p-0" v-if="isOTPDone">
                <div class="row px-77 w-100 text-center text_body  padding-top-54 height-189">
                    <div class="col-md-12">
                        <h3 class="text-color">{{$t('shared.requestSuccessfully')}} !!</h3>
                        <h6 v-if="isNotemsg" class="text-red mt-3">{{$t('shared.Notemsg')}}</h6>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="form-row justify-content-between align-items-center">
                        <div class="col-auto mx-4">

                        </div>
                        <div class="col-auto">

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="login_footer form-row justify-content-between align-items-center">
            <div class="col-auto">
                <a>
                    {{$t('shared.privacy')}}
                </a>
            </div>
            <div class="col-auto">
                <a>
                    {{$t('shared.termsOfUse')}}
                </a>
            </div>
            <div class="col-auto">
                <a>
                    {{$t('shared.copyRight')}} {{currentYear}}
                </a>
            </div>
        </div>
    </div>
</div>
