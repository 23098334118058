import { Inject } from 'vue-di-container/dist/decorators';
import Progress from 'easy-circular-progress';
import { Component, Vue } from 'vue-property-decorator';
import { Toaster } from '../../Services/toast';
import { AdvertiserDashbordModel } from './AdvertiserDashbordModel';
import { AdvertiserFilterModel } from './AdvertiserFilterModel';
import AdvertiserRequwstsService from './AdvertiserRequwstsService';
import WithRender from './RequestListDashboard.html';
import { AdvertiserAttachmentModel } from '../AddNewRequest/AdvertiserAttachmentModel';
import FileSaver from 'file-saver';

@WithRender
@Component({
    components: {
        Progress,
    },
    diProvide: [
        AdvertiserRequwstsService
    ],
})
export default class RequestListDashboard extends Vue {
    @Inject(AdvertiserRequwstsService) public advertiserRequwstsService!: AdvertiserRequwstsService;
    public pagination: any = {};
    public headers: any = [

        { text: this.$t('RequestListDashboard.orderNumber'), sortable: false, value: 'orderNumber', align: 'center' },
        { text: this.$t('RequestListDashboard.orderStatus'), sortable: false, value: 'orderStatus', width: '110' ,align: 'center' },
        { text: this.$t('RequestListDashboard.RequestsDate'), sortable: true, value: 'RequestsDate', align: 'center' },
        { text: this.$t('RequestListDashboard.RemainingDays'), sortable: false, value: 'RemainingDays', width: '120', align: 'center' },
        { text: this.$t('RequestListDashboard.Actions'), sortable: false, value: 'Actions', align: 'center' }
    ];
    public advertiser: AdvertiserDashbordModel[] = [];
    public advertiserCount: number = 0;
    public orderNumber: string = '';
    public status: number = 0;
    public fileName: string = '';
    public fileContent: string = '';
    public async AddNewRequest() {
        this.redirectToAddNewRequestPage();
    }

    redirectToAddNewRequestPage() {
        this.$router.push({ name: 'AddNewRequest' });
    }
    public getRate(remainingDays: string, totalDays: string) {
        return (parseInt(remainingDays, 10) / parseInt(totalDays, 10)) * 100;
    }
    public async mounted() {
        this.search();
    }
    public async search() {
        let model;
        model = {
            orderNumber: this.orderNumber,
            status: this.status,
            pageIndex: '0',
            pageSize: '10',
        };

        localStorage.removeItem('storSettings');
        const response = await this.advertiserRequwstsService.Get(model);
            this.advertiser = response.result;
        this.advertiserCount = response.totalCount;

    }
    public async updatePaginate(val: any) {
        const model = {
            orderNumber: this.orderNumber,
            status: this.status,
            pageIndex: (val.page - 1).toString(),
            pagesize: val.rowsPerPage,
            sortDirection: this.pagination.descending,
            sortBy: this.pagination.sortBy,
        };
        const response = await this.advertiserRequwstsService.Get(model);
        this.advertiser = response.result;
        this.advertiserCount = response.totalCount;
    }
    public async Edit(id: string, fileName: string) {
        this.$router.push({ name: 'AddNewRequest', params: { id, fileName } });
    }
    public statusType = [
        {
            id: 1,
            name: this.$t('shared.PendingPaymen').toString(),
        },
        {
            id: 2,
            name: this.$t('shared.UnderProcessing').toString(),
        },
        {
            id: 3,
            name: this.$t('shared.Completed').toString(),
        },
        {
            id: 4,
            name: this.$t('shared.Expired').toString(),
        },
    ];

    public checkColor(typeStatus: string) {
        typeStatus = typeStatus.toString();
        switch (typeStatus) {
            case '1': {
                return '#c427ba';
                break;
            }
            case '2': {
                return '#aaa7a7';
                break;
            }
            case '3': {
                return '#5aaa0b';
                break;
            }
            case '4': {
                return '#C42727';
                break;
            }

            default: {
                return '#F78500';
                break;
            }
        }
    }
    public GetSenderStatus(typeStatus: string) {
        typeStatus = typeStatus.toString();
        switch (typeStatus) {
            case '1': {
                return 'Pending Payment';
                break;
            }
            case '2': {
                return 'Processing';
                break;
            }
            case '3': {
                return 'Completed';
                break;
            }
            case '4': {
                return 'Expired';
                break;
            }
            default: {
                return 'Deactivated';
                break;
            }
        }
    }
    public changeSort(column: any) {
        if (this.pagination.sortBy === column) {
            this.pagination.descending = !this.pagination.descending;
        } else {
            this.pagination.sortBy = column;
            this.pagination.descending = false;
        }
        this.updatePaginate(this.pagination);
    }
    public async DownloadFile(file: string) {
        const response = await this.advertiserRequwstsService.GetFileConten(file);

        this.fileContent = response.result.content;
        this.fileName = response.result.fileName;

        const array = Buffer.from(this.fileContent, 'base64');
        const blob = new Blob([array], { type: 'application/pdf' });
        FileSaver.saveAs(blob, this.fileName);
    }
    public Pay(id: string) {
        this.$router.push({ name: 'AddNewRequest', params: { id } });
    }
    public canDownload(record: any): boolean {
        const parts = record.split('/');         
        const recordDate = new Date(parseInt(parts[2]), parseInt(parts[1]) - 1, parseInt(parts[0]));
        const currentDate = new Date(); 
        const differenceInMilliseconds = currentDate.getTime() - recordDate.getTime();
        // Calculate the number of days by dividing milliseconds by milliseconds in a day (1000 * 60 * 60 * 24)
        const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
        
        return differenceInDays <= 30;        
    }
}