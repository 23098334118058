import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import WithRender from './UpdateContractRequest.html';
import { Inject } from 'vue-di-container';
import UpdateContractRequestService from './UpdateContractRequestService';
import { DropdownModel } from '../../Provider/ProviderModel';
import CITCKeywordsService from '../../CITCKeywords/CITCKeywordsService';
import ProviderService from '../../Provider/ProviderService';
import { ConnectingSenderNameModel, SenderViewModel } from './UpdateContractRequestModel';
import ValidationService from '../../../Services/ValidationService';
import UserService from '../../../shared/userService/UserService';
import { Toaster } from '../../../Services/toast';
import { SenderType } from '../../../shared/Enums/SenderType';
import { AttachmentModel } from '../../AddRequest/AttachmentModel';

@WithRender
@Component({
    components: {
    },
    diProvide: [
        UpdateContractRequestService,
        CITCKeywordsService,
        ProviderService,
    ],
})
export default class UpdateContractRequest extends Vue {
    @Inject(ValidationService) public validationService!: ValidationService;
    @Inject(UpdateContractRequestService) public UpdateContractRequestService!: UpdateContractRequestService;
    @Inject(CITCKeywordsService) public cITCKeywordsService!: CITCKeywordsService;
    @Inject(ProviderService) public providerService!: ProviderService;
    @Inject(UserService) public userService!: UserService;
    @Prop({ type: Boolean, default: false })
    public layoutSup: boolean | any;


    @Prop({ default: 0 })
    public id!: string;

    public minDate: Date = new Date();
    public stepNumber: number = 1;
    public senderName: string = '';
    public typeFile: number = 0;
    public senderNameLength: number = 11;
    public contractAttachmentName: string = '';
    public senderType: number[] = [];
    public expiryDateFromMenu: boolean = false;

    public sendersList: string[] = [];
    public submitRequestdialog: boolean = false;
    public isCriticalSender: boolean = false;
    public senderNameId: number = 0;
    public headers: any = [
        { text: this.$t('shared.senderName'), value: 'operatorId', align: 'right', sortable: false },
        { text: this.$t('shared.senderType'), value: 'operatorId', align: 'center', sortable: false },
        { text: this.$t('shared.clientName'), value: 'name', align: 'center', sortable: false }
    ];
    public customerTypeList: DropdownModel[] = [
        {
            id: 1,
            name: this.$t('shared.government').toString(),
        },
        {
            id: 2,
            name: this.$t('shared.charity').toString(),
        },
        {
            id: 3,
            name: this.$t('shared.private').toString(),
        },
        {
            id: 4,
            name: this.$t('shared.international').toString(),
        },
    ];

    public senderTypeList: DropdownModel[] = [
        {
            id: SenderType.Advertisement,
            name: this.$t('shared.advertisement').toString(),
        },
        {
            id: SenderType.Service,
            name: this.$t('shared.service').toString(),
        },
        {
            id: SenderType.Awareness,
            name: this.$t('shared.awareness').toString(),
        },
        {
            id: SenderType.Warning,
            name: this.$t('shared.warning').toString(),
        },
    ];

    public model: ConnectingSenderNameModel = {
        id: '',
        duration:'1',
        startDate: '',
        clientName: '',
        customerType: 0,
        crNumber: '',
        enterpriseUnifiedNumber: '',
        senderName: '',
        contractAttachment: null,
        requestType: 7,
        comment: '',
        expiryDate:'',
        senderType :0
    };
    
    senderNames: SenderViewModel[]=[];
    public selected: SenderViewModel[] = [];
    public selectedIds: string[] = [];

    public async created() {
        this.$emit('childinit', this.layoutSup);
        let user = await this.userService.getUser();
        let providerId = user.details.ProviderId;
        let provider = await this.providerService.GetById( providerId);
        if (! provider.hasInternational) {
            this.customerTypeList.splice(3, 1);
        }
        const response = await this.UpdateContractRequestService.get(this.id);
        if (response.isSuccess) {
            this.model.id = response.result.id;
            this.model = response.result;
            this.model.expiryDate='';
            this.getSenderType();

        } else {
            Toaster.error(response.errorMessage);
        }
    }
    
    public next() {
        if (this.stepNumber <3 ) {
            this.stepNumber = this.stepNumber + 1;
        }
    } 
    


    public back() {
        this.stepNumber = this.stepNumber - 1;
    }

    @Watch('model.customerType', { deep: true })
    public onchangeCustomerType() {
        switch (this.model.customerType) {
            case 1:
                this.senderTypeList = [
                    {
                        id: SenderType.Advertisement,
                        name: this.$t('shared.advertisement').toString(),
                    },
                    {
                        id: SenderType.Service,
                        name: this.$t('shared.service').toString(),
                    },
                    {
                        id: SenderType.Awareness,
                        name: this.$t('shared.awareness').toString(),
                    },
                    {
                        id: SenderType.Warning,
                        name: this.$t('shared.warning').toString(),
                    },
                ];
                this.senderName = '';
                break;
            case 2:
                this.senderTypeList = [
                    {
                        id: SenderType.Advertisement,
                        name: this.$t('shared.advertisement').toString(),
                    },
                    {
                        id: SenderType.Service,
                        name: this.$t('shared.service').toString(),
                    },
                    {
                        id: SenderType.Awareness,
                        name: this.$t('shared.awareness').toString(),
                    },
                ];
                this.senderName = '';
                break;
            case 3:
                this.senderTypeList = [
                    {
                        id: SenderType.Advertisement,
                        name: this.$t('shared.advertisement').toString(),
                    },
                    {
                        id: SenderType.Service,
                        name: this.$t('shared.service').toString(),
                    },
                    {
                        id: SenderType.Awareness,
                        name: this.$t('shared.awareness').toString(),
                    },
                ];
                break;
            case 4:
                this.senderTypeList = [
                    {
                        id: SenderType.Service,
                        name: this.$t('shared.service').toString(),
                    },
                ];
                this.senderType = [SenderType.Service];
                break;
        }
    }

    
    public showsubmitRequestDialog(id: string) {
        this.submitRequestdialog = true;
    }

    public hidesubmitRequestDialog() {
        this.submitRequestdialog = false;
    }

    public async submitRequest() {
        const response = await this.UpdateContractRequestService.post(this.model);
        if (response.isSuccess) {
            this.stepNumber = 4;
        } else {
            Toaster.error(response.errorMessage);
        }
        this.hidesubmitRequestDialog();
    }

    public newRequest() {
        this.reset();
        this.stepNumber = 1;
    }

    

    public select(event: any, id: string) {
        if (!event) {
            const index = this.selectedIds.indexOf(id);
            this.selectedIds.splice(index, 1);
        } else {
            this.selectedIds.push(id);
        }
    }


    public deleteContract() {
        this.model.contractAttachment= null;
        this.contractAttachmentName = '';
        (this.$refs.contractAttachment as any).value = '';
    }

    public getCustomerTypeText(typeId: number) {
        switch (typeId) {
            case 1:
                return this.$t('shared.government').toString();
            case 2:
                return this.$t('shared.charity').toString();
            case 3:
                return this.$t('shared.private').toString();
            case 4:
                return this.$t('shared.international').toString();
            default:
                return '-';
        }
    }

    public getSenderType() {
        const senderBinary = this.model.senderType;
        if ((senderBinary & 1) === 1) {
            this.senderType.push(1);
        }
        if ((senderBinary & 2) === 2) {
            this.senderType.push(2);
        }
        if ((senderBinary & 4) === 4) {
            this.senderType.push(4);
        }
        if ((senderBinary & 8) === 8) {
            this.senderType.push(8);
        }
    }

    public getSenderTypeText(typeId: number) {
        switch (typeId) {
            case SenderType.Advertisement:
                return this.$t('shared.advertisement').toString();
            case SenderType.Service:
                return this.$t('shared.service').toString();
            case SenderType.Awareness:
                return this.$t('shared.awareness').toString();
            case SenderType.Warning:
                return this.$t('shared.warning').toString();
            default:
                return '-';
        }
    }

    public onPickFile(type: number) {
        this.typeFile = type;

                (this.$refs.contractAttachment as any).click();
     
    }

    public onFilePicked(event: any) {
        const files: any[] = event.target.files;
        const modelArray: AttachmentModel = {} as AttachmentModel;
        Array.prototype.forEach.call(files, (file, index) => {
            modelArray.fileName = file.name;
            this.contractAttachmentName = file.name;
           
            const reader = new FileReader();
            reader.onloadend = (fileData: any) => {
                modelArray.fileContent = fileData.target.result.split(',')[1];
                modelArray.attachmentType = this.typeFile;
                this.model.contractAttachment= modelArray;
            };
            reader.readAsDataURL(file);
        });
    }

    public get isValidStep(): boolean {
            return (
                this.model.expiryDate !== "" &&
                new Date( this.model.expiryDate) > this.minDate &&
                this.model.contractAttachment!= null && this.model.contractAttachment.attachmentType === 1);
    }
    
    public reset() {
        this.model = {
            id:this.id,
            duration: '1',
            startDate: '',
            clientName: this.model.clientName,
            crNumber: this.model.crNumber,
            enterpriseUnifiedNumber: this.model.enterpriseUnifiedNumber,
            customerType: this.model.customerType,
            senderName: this.model.senderName,
            contractAttachment: null,
            requestType: 7,
            comment: this.model.comment,
            expiryDate:'',
            senderType :this.model.senderType
        };
        this.contractAttachmentName = '';
        this.senderType = [];
    }

}
