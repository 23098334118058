import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import WithRender from './EditSender.html';
import { Inject } from 'vue-di-container';
import EditSenderService from './EditSenderService';
import ValidationService from '../../../Services/ValidationService';
import { AttachmentModel } from '../../AddRequest/AttachmentModel';
import ShortService from '../../Short/ShortService';
import { SenderModel } from '../SenderModel';
import { Toaster } from '../../../Services/toast';
import { DropdownModel, ProviderModel } from '../../Provider/ProviderModel';
import moment from 'moment';
import { SenderType } from '../../../shared/Enums/SenderType';
import CITCKeywordsService from '../../CITCKeywords/CITCKeywordsService';
import { CITCKeywordsModel } from '../../CITCKeywords/CITCKeywordsModel';
import ProviderService from '../../Provider/ProviderService';
import UserService from '../../../shared/userService/UserService';
import UserModel from '../../../shared/userService/Model/UserModel';


@WithRender
@Component({
    components: {
    },
    diProvide: [
        EditSenderService,
        ShortService,
        CITCKeywordsService,
        ProviderService,
    ],
})
export default class EditSender extends Vue {
    @Inject(ValidationService) public validationService!: ValidationService;
    @Inject(EditSenderService) public editSenderService!: EditSenderService;
    @Inject(ShortService) public shortService!: ShortService;
    @Inject(CITCKeywordsService) public cITCKeywordsService!: CITCKeywordsService;
    @Inject(ProviderService) public providerService!: ProviderService;
    @Inject(UserService) public userService!: UserService;

    @Prop({ type: Boolean, default: false })
    public layoutSup: boolean | any;

    @Prop({ default: 0 })
    public id!: string;
    public typeFile: number = 0;
    public providerId: string = '';
    public senderNameLength: number = 11;
    public senderType: SenderType[] = [];
    public keywordsList: CITCKeywordsModel[] = [];
    public editSenderDialog: boolean = false;
    public fileNameAdditionalAttachments: string = '';
    public fileNameRegistrationDocument: string = '';
    public fileNameDelegationLetter: string = '';
    public user: UserModel = {
        userId: '',
        username: '',
        details: {
            Email: '',
            MobilePhone: '',
            Operator: '',
            Provider: '',
            OperatorId: '',
            ProviderId: '',
            Role: '',
            TenantId: '',
            ChangePasswordRequired: '',
        },
    };
    public provider: ProviderModel = {
        name: '',
        id: '',
        createdDate: null,
        crNumber: '',
        accreditationNumber: '',
        email: '',
        taxNumber: '',
        hasInternational: false,
        country: '',
        city: '',
        district: '',
        streetName: '',
        buildingNumber: '',
        postalCode: '',
        vatNumber: '',
        additionalNumber: '',
        otherIdentifier: '',
        balance: 0,
        creditLimitAmount: 0,
        bankAccountNumber: '',
    };
    public customerTypeList: DropdownModel[] = [
        {
            id: 1,
            name: this.$t('shared.government').toString(),
        },
        {
            id: 2,
            name: this.$t('shared.charity').toString(),
        },
        {
            id: 3,
            name: this.$t('shared.private').toString(),
        },
        {
            id: 4,
            name: this.$t('shared.international').toString(),
        },
    ];
    public senderTypeList: DropdownModel[] = [
        {
            id: SenderType.Advertisement,
            name: this.$t('shared.advertisement').toString(),
        },
        {
            id: SenderType.Service,
            name: this.$t('shared.service').toString(),
        },
        {
            id: SenderType.Awareness,
            name: this.$t('shared.awareness').toString(),
        },
        {
            id: SenderType.Warning,
            name: this.$t('shared.warning').toString(),
        },
    ];
    public model: SenderModel = {
        id: '',
        duration: '',
        senderType: 0,
        newSenderType: 0,
        senderStatus: 0,
        startDate: '',
        senderId: '',
        clientName: '',
        crNumber: '',
        enterpriseUnifiedNumber: '',
        providerName: '',
        customerType: 0,
        representativeEmail: '',
        representativeMobile: '',
        representativeName: '',
        senderRequestAttachments: [],
        senderName: '',
        requestStatus: 0,
        lastRejectionReason: '',
        remainingDays: 0,
        isAlreadyExist: false,
        totalDays: 0,
        comment: '',
        isCriticalSender: false,
        tenantId: '',
    };

    public async created() {
        this.$emit('childinit', this.layoutSup);
        const keywordsListdata = await this.cITCKeywordsService.getAll();
        this.keywordsList = keywordsListdata.result;
    }

    public async mounted() {
        const response = await this.editSenderService.get(this.id);
        if (response.isSuccess) {
            this.model = response.result;
        } else {
            Toaster.error(response.errorMessage);
        }

        this.fileNameAdditionalAttachments = this.model.senderRequestAttachments.filter((x) =>
            x.attachmentType === 3).map((a) => a.fileName).join(' / ');
        this.fileNameRegistrationDocument = this.model.senderRequestAttachments
            .filter((x) => x.attachmentType === 2).length !== 0 ?
            this.model.senderRequestAttachments.filter((x) => x.attachmentType === 2)[0].fileName : '';
        this.fileNameDelegationLetter =
            this.model.senderRequestAttachments.filter((x) => x.attachmentType === 1)[0].fileName;
        this.getSenderType();
        if (this.senderType.includes(SenderType.Advertisement)) {
            this.senderNameLength = 8;
        } else if (this.senderType.includes(SenderType.Service) && this.model.customerType === 4) {
            this.senderNameLength = 9;
        } else {
            this.senderNameLength = 11;
        }

        this.user = await this.userService.getUser();
        this.providerId = this.user.details.ProviderId;
        if (this.providerId != null) {
            this.provider = await this.providerService.GetById(this.providerId);
            if (!this.provider.hasInternational) {
                this.customerTypeList.splice(3, 1);
            }
        }
    }
    public isMobileNumberValid() {
        if (!this.model.representativeMobile.startsWith('5')) {
            Toaster.error(this.$t('shared.InvalidMobileNumber').toString());
        }
    }
    @Watch('model.customerType', { deep: true })
    public onchangeCustomerType(newVal: number, oldVal: number) {
        switch (this.model.customerType) {
            case 1:
                this.senderTypeList = [
                    {
                        id: SenderType.Advertisement,
                        name: this.$t('shared.advertisement').toString(),
                    },
                    {
                        id: SenderType.Service,
                        name: this.$t('shared.service').toString(),
                    },
                    {
                        id: SenderType.Awareness,
                        name: this.$t('shared.awareness').toString(),
                    },
                    {
                        id: SenderType.Warning,
                        name: this.$t('shared.warning').toString(),
                    },
                ];
                break;
            case 2:
                this.senderTypeList = [
                    {
                        id: SenderType.Advertisement,
                        name: this.$t('shared.advertisement').toString(),
                    },
                    {
                        id: SenderType.Service,
                        name: this.$t('shared.service').toString(),
                    },
                    {
                        id: SenderType.Awareness,
                        name: this.$t('shared.awareness').toString(),
                    },
                ];
                break;
            case 3:
                this.senderTypeList = [
                    {
                        id: SenderType.Advertisement,
                        name: this.$t('shared.advertisement').toString(),
                    },
                    {
                        id: SenderType.Service,
                        name: this.$t('shared.service').toString(),
                    },
                    {
                        id: SenderType.Awareness,
                        name: this.$t('shared.awareness').toString(),
                    },
                ];
                break;
            case 4:
                this.senderTypeList = [
                    {
                        id: SenderType.Service,
                        name: this.$t('shared.service').toString(),
                    },
                ];
                this.senderType = [SenderType.Service];
                break;
        }
    }

    @Watch('senderType', { deep: true })
    public onchangeSenderType(newVal: SenderType[], oldVal: SenderType[]) {
        if (newVal.length > 0 && newVal !== oldVal) {
            if (this.senderType.includes(SenderType.Advertisement)) {
                this.senderNameLength = 8;
            } else if (this.senderType.includes(SenderType.Service) && this.model.customerType === 4) {
                this.senderNameLength = 9;
            } else {
                this.senderNameLength = 11;
            }
        }
    }

    public getSenderType() {
        const senderBinary = this.model.senderType;

        if ((senderBinary & 1) === 1) {
            this.senderType.push(1);
        }
        if ((senderBinary & 2) === 2) {
            this.senderType.push(2);
        }
        if ((senderBinary & 4) === 4) {
            this.senderType.push(4);
        }
        if ((senderBinary & 8) === 8) {
            this.senderType.push(8);
        }
    }

    public getCustomerTypeText(typeId: number) {
        switch (typeId) {
            case 1:
                return this.$t('shared.government').toString();
            case 2:
                return this.$t('shared.charity').toString();
            case 3:
                return this.$t('shared.private').toString();
            case 4:
                return this.$t('shared.international').toString();
            default:
                return '-';
        }
    }

    public isNumberField(val: KeyboardEvent) {
        if (!this.validationService.isNumber(val.key)) {
            val.preventDefault();
        }
    }

    public isSenderNameField(val: KeyboardEvent) {
        const pattern = /^[-a-zA-Z0-9._& ]*$/;
        if (!pattern.test(val.key)) {
            val.preventDefault();
        }
    }

    public isNumberFieldPaste(ev: any) {
        const paste = (ev.clipboardData).getData('text');
        if (isNaN(paste)) {
            ev.preventDefault();
        }
    }

    public isSenderNameFieldPaste(ev: any) {
        const paste = (ev.clipboardData).getData('text');
        const pattern = /^[-a-zA-Z0-9._& ]*$/;
        if (!pattern.test(paste)) {
            ev.preventDefault();
        }
    }

    public deleteAdditionalAttachments() {
        this.model.senderRequestAttachments.forEach((x, i) => {
            if (x.attachmentType === 3) {
                this.model.senderRequestAttachments.splice(i, 1);
            }
        });
        this.fileNameAdditionalAttachments = '';
        (this.$refs.fileAdditionalAttachments as any).value = '';
    }

    public deleteRegistrationDocument() {
        this.model.senderRequestAttachments.forEach((x, i) => {
            if (x.attachmentType === 2) {
                this.model.senderRequestAttachments.splice(i, 1);
            }
        });
        this.fileNameRegistrationDocument = '';
        (this.$refs.fileRegistrationDocument as any).value = '';
    }

    public deleteDelegationLetter() {
        this.model.senderRequestAttachments.forEach((x, i) => {
            if (x.attachmentType === 1) {
                this.model.senderRequestAttachments.splice(i, 1);
            }
        });
        this.fileNameDelegationLetter = '';
        (this.$refs.fileDelegationLetter as any).value = '';
    }

    public onPickFile(type: number) {
        this.typeFile = type;
        switch (type) {
            case 1:
                (this.$refs.fileDelegationLetter as any).click();
                break;
            case 2:
                (this.$refs.fileRegistrationDocument as any).click();
                break;
            case 3:
                (this.$refs.fileAdditionalAttachments as any).click();
                break;
        }
    }

    public onFilePicked(event: any) {
        this.model.senderRequestAttachments =
            this.model.senderRequestAttachments.filter((x) => x.attachmentType !== this.typeFile);
        const files: any[] = event.target.files;
        const modelArray: AttachmentModel = {} as AttachmentModel;
        Array.prototype.forEach.call(files, (file, index) => {
            modelArray.fileName = file.name;
            switch (this.typeFile) {
                case 1:
                    this.fileNameDelegationLetter = file.name;
                    break;
                case 2:
                    this.fileNameRegistrationDocument = file.name;
                    break;
                case 3:
                    if (index === 0) {
                        this.fileNameAdditionalAttachments = '';
                    }
                    if (files.length === (index + 1)) {
                        this.fileNameAdditionalAttachments += file.name;
                    } else {
                        this.fileNameAdditionalAttachments += file.name + ' / ';
                    }
                    break;
            }
            const reader = new FileReader();
            reader.onloadend = (fileData: any) => {
                modelArray.fileContent = fileData.target.result.split(',')[1];
                modelArray.attachmentType = this.typeFile;
                this.model.senderRequestAttachments.push(modelArray);
            };
            reader.readAsDataURL(file);
        });
    }

    public get isValid(): boolean {
        return (
            this.model.clientName.trim() !== '' &&
            this.model.customerType !== 0 &&
            this.model.crNumber.trim() !== '' &&
            (this.model.customerType === 1 || this.model.customerType === 4
                || this.model.enterpriseUnifiedNumber.trim() !== '') &&
            this.model.representativeName.trim() !== '' &&
            this.model.representativeMobile !== '' &&
            (this.model.customerType === 4 ||
                this.model.representativeMobile.length === 9) &&
            (this.model.customerType === 4 ||
                this.model.representativeMobile.startsWith('5')) &&
            this.model.representativeEmail !== '' &&
            this.validationService.isValidEmail(this.model.representativeEmail) &&
            this.model.duration.toString().trim() !== '' &&
            this.model.duration.toString().trim() !== '0' &&
            this.model.senderRequestAttachments.filter((x) => x.attachmentType === 1).length !== 0 &&
            (this.model.customerType === 1 || (this.model.customerType !== 1 &&
                this.model.senderRequestAttachments.filter((x) => x.attachmentType === 2).length !== 0)) &&
            this.model.senderName.trim() !== '' &&
            this.senderType.length !== 0
        );
    }
    public get isAdminEdit(): boolean {
        return (
            this.user.details.Role === 'Platform Admin' || this.user.details.Role === 'Super Admin'
        );
    }
    public showEditSenderDialog(id: string) {
        this.editSenderDialog = true;
    }
    public hideEditSenderDialog() {
        this.editSenderDialog = false;
    }
    public async save() {
        if (this.keywordsList.filter((b) => this.model.senderName.trim().toLowerCase()
            === b.name.toLowerCase()).length > 0) {
            Toaster.error(this.$t('shared.senderNameShouldNotBeOneOfTheKeywords').toString());
            return;
        }
        this.model.senderType = this.senderType.reduce((a, b) => a + b, 0);
        if (this.model.senderName.trim().length < 2) {
            Toaster.error(this.$t('shared.senderNameShouldbe2OrmoreChar').toString());
            return;
        }
        if (/^\d+$/.test(this.model.senderName.trim())) {
            Toaster.error(this.$t('shared.senderNameShouldbenumbersandChar').toString());
            return;
        }
        if (this.model.senderName.trim().slice(-3).toLocaleLowerCase() !== '-ad' &&
            this.model.senderName.trim().slice(-2).toLocaleLowerCase() !== '-i') {
            this.model.senderName = this.model.senderName.trim();
            const IsAdmin = (this.user.details.Role === 'Platform Admin' ||
                this.user.details.Role === 'Super Admin');
            let response: any;
            if (IsAdmin) {
                response = await this.editSenderService.editSender(this.model);
            } else {
                response = await this.editSenderService.put(this.model);
            }
            if (response.isSuccess) {
                Toaster.success(this.$t('shared.editSenderSuccessfully').toString());
                this.$router.push({ name: 'SenderInfo', params: { id: this.id.toString() } });
            } else {
                Toaster.error(response.errorMessage);
            }
        } else {
            Toaster.error(this.$t('shared.errorSenderNameEnd').toString());
        }

        this.hideEditSenderDialog();
    }
}
