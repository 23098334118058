import { Component, Vue, Watch } from 'vue-property-decorator';
import WithRender from './MonitorDashboard.html';
import Chart from 'chart.js'
import { ChartDashboardStatusBoxesModel } from './ChartDashboardStatusBoxesModel';
import { ChartDashboardBoxesModel } from './DashboardBoxesModel';
import ChartDashboardService from './ChartDashboardService';
import { Inject } from 'vue-di-container';
import { DropdownModel } from '../Provider/ProviderModel';
import { AlertModel } from './AlertModel';
import { ChartModel } from './ChartModel';
import { MonitorDashboardOptions } from './MonitorDashboardOptions';
import VueHtml2pdf from 'vue-html2pdf'

@WithRender
@Component({
    components: {
        VueHtml2pdf
    },
    diProvide: [
        ChartDashboardService
    ],
})

export default class MonitorDashboard extends Vue {
    @Inject(ChartDashboardService) public chartDashboardService!: ChartDashboardService;
    public chartFilter = {
        dateFrom: "",
        dateTo: "",
    }
    public createdDateToMenu: boolean = false;
    public createdDateFromMenu: boolean = false;
    public chartLineData: any = '';
    public interval: any;
    public items: DropdownModel[] = [
        { id: 1, name: 'All' },
        { id: 2, name: 'Governmental' },
        { id: 3, name: 'Private' },
        { id: 4, name: 'Charities' },
        { id: 5, name: 'international' }
    ];
    public activated: number = 1;
    public approved: any = 1;
    public paid: any = 1;
    public pendingForApproval: any = 1;
    public rejected: any = 1;
    public dashboardBoxes: ChartDashboardBoxesModel = {
        all: 0,
        charity: 0,
        government: 0,
        international: 0,
        private: 0
    };
    public monitorDashboardOptions: MonitorDashboardOptions = {
        reloadInterval: 0,
    };
    public dashboardStatusBoxes: ChartDashboardStatusBoxesModel = {
        activated: {
            all: 0,
            charity: 0,
            government: 0,
            international: 0,
            private: 0,
            allCount: 0,
            charityCount: 0,
            governmentCount: 0,
            internationalCount: 0,
            privateCount: 0
        },
        approved: {
            all: 0,
            charity: 0,
            government: 0,
            international: 0,
            private: 0,
            allCount: 0,
            charityCount: 0,
            governmentCount: 0,
            internationalCount: 0,
            privateCount: 0
        },
        paid: {
            all: 0,
            charity: 0,
            government: 0,
            international: 0,
            private: 0,
            allCount: 0,
            charityCount: 0,
            governmentCount: 0,
            internationalCount: 0,
            privateCount: 0
        },
        pendingForApproval: {
            all: 0,
            charity: 0,
            government: 0,
            international: 0,
            private: 0,
            allCount: 0,
            charityCount: 0,
            governmentCount: 0,
            internationalCount: 0,
            privateCount: 0
        },
        rejected: {
            all: 0,
            charity: 0,
            government: 0,
            international: 0,
            private: 0,
            allCount: 0,
            charityCount: 0,
            governmentCount: 0,
            internationalCount: 0,
            privateCount: 0
        }
    };
    public pendingApprovals: AlertModel[] = [
        { percentage: 0, dayOrder: "", count: 0 },
        { percentage: 0, dayOrder: "", count: 0 },
        { percentage: 0, dayOrder: "", count: 0 },
        { percentage: 0, dayOrder: "", count: 0 },
    ];
    public pendingPaymentAlerts: AlertModel[] = [
        { percentage: 0, dayOrder: "", count: 0 },
        { percentage: 0, dayOrder: "", count: 0 },
        { percentage: 0, dayOrder: "", count: 0 },
        { percentage: 0, dayOrder: "", count: 0 },
    ];
    public pendingActivationAlerts: AlertModel[] = [
        { percentage: 0, dayOrder: "", count: 0 },
        { percentage: 0, dayOrder: "", count: 0 },
        { percentage: 0, dayOrder: "", count: 0 },
        { percentage: 0, dayOrder: "", count: 0 },
    ];
    allRequestsChart: ChartModel[] = [];
    TotalRequestsChart: number = 0;
    public async mounted() {
        this.monitorDashboardOptions = await this.chartDashboardService.GetMonitorDashboardOptions();
        this.reload();
        this.timeInterval();
    }
    public async reload() {
        this.chartFilter = await this.chartDashboardService.GetDefaultDates();
        this.dashboardBoxes = await this.chartDashboardService.GetDashboardBoxes();
        this.pendingApprovals = await this.chartDashboardService.PendingApprovalAlerts();
        this.pendingPaymentAlerts = await this.chartDashboardService.PendingPaymentAlerts();
        this.pendingActivationAlerts = await this.chartDashboardService.PendingActivationAlerts();
        this.dashboardStatusBoxes = await this.chartDashboardService.GetDashboardStatusBoxes(this.chartFilter);
        this.allRequestsChart = await this.chartDashboardService.AllRequestsChart(this.chartFilter);
        this.loadData();
    }

    public timeInterval() {
        this.interval = setInterval(() => this.reload(), this.monitorDashboardOptions.reloadInterval);
    }
    public async loadData() {
        this.TotalRequestsChart = this.allRequestsChart
            .map(item => item.count)
            .reduce((prev, curr) => prev + curr, 0);
        this.chartLineData = {
            type: "line",
            data: {
                labels: this.allRequestsChart.map(s => new Date(s.date)),
                datasets: [
                    {
                        label: 'Requests',
                        data: this.allRequestsChart.map(s => Number(s.count)),
                        borderColor: '#4C86F9',
                        backgroundColor: '#4C86F933',
                        tension: 0,
                        borderWidth: 2,
                        pointBackgroundColor: "#4C86F9"
                    },
                ]
            },
            options: {
                tooltips: {
                    yAlign: 'bottom',
                },
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    align: 'end',
                    labels: {
                        fontColor: 'black',
                        usePointStyle: true,
                    }
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            suggestedMin: 0,
                            display: false,
                        },
                        gridLines: {
                            drawBorder: false,
                            zeroLineColor: '#4C86F9',
                            zeroLineWidth: 3,
                        },
                        scaleLabel: {
                            labelString: 'ss',
                        }
                    }],
                    xAxes: [{
                        type: 'time',
                        time: {
                            parser: 'MMM D',
                            tooltipFormat: 'ddd DD MMM',
                            unit: 'day',
                            unitStepSize: 4,
                            displayFormats: {
                                day: 'MMM D',
                            },
                        },
                        gridLines: {
                            drawBorder: false,
                            display: false,
                        },

                    }],
                }
            },
        }
        const chart = document.getElementById('line_charts');
        const reportChart = document.getElementById('line_charts_report');
        new Chart(chart, this.chartLineData);
        new Chart(reportChart, this.chartLineData);
    }
    percentage(value: any) {
        return {
            width: value + '%',
        };
    }
    generateReport() {
        (this.$refs.html2Pdf as any).generatePdf();
    }

    @Watch('chartFilter', { deep: true })
    public async updateDateRelatedElements() {
        this.allRequestsChart = await this.chartDashboardService.AllRequestsChart(this.chartFilter);
        this.dashboardStatusBoxes = await this.chartDashboardService.GetDashboardStatusBoxes(this.chartFilter);
        this.loadData();
    }
}