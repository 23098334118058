<div class="w-100 h-100">
    <div class="row" style="padding-top: 25px;padding-bottom: 25px;">
        <div class="col-md-12">
            <div class="form-row justify-content-between align-items-baseline">
                <div class="col-auto">
                    <label class="header_text mb-3">
                        {{$t('shared.providerRequestInfo')}}
                    </label>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="card card_layout">
                <div class="card-body py-5 px-5">
                    <div class="row mx-3">
                        <div class="col-sm-4">
                            <label class="user_info_header">{{$t('shared.name')}}</label>
                            <h4 class="user_info_txt">{{providerRequest.name}}</h4>
                        </div>
                        <div class="col-sm-4">
                            <label class="user_info_header">{{$t('shared.crNumber')}}</label>
                            <h4 class="user_info_txt">{{providerRequest.crNumber}}</h4>
                        </div>
                        <div class="col-sm-4">
                            <label class="user_info_header">{{$t('shared.accreditationNumber')}}</label>
                            <h4 class="user_info_txt">{{providerRequest.accreditationNumber}}</h4>
                        </div>
                        <div class="col-sm-4">
                            <label class="user_info_header">{{$t('shared.taxNumber')}}</label>
                            <h4 class="user_info_txt">{{providerRequest.taxNumber}}</h4>
                        </div>
                        <div class="col-sm-4">
                            <label class="user_info_header">{{$t('shared.email')}}</label>
                            <h4 class="user_info_txt">{{providerRequest.email}}</h4>
                        </div>
                        <div class="col-sm-4">
                            <label class="user_info_header">{{$t('shared.country')}}</label>
                            <h4 class="user_info_txt">{{providerRequest.country}}</h4>
                        </div>
                        <div class="col-sm-4">
                            <label class="user_info_header">{{$t('shared.city')}}</label>
                            <h4 class="user_info_txt">{{providerRequest.city}}</h4>
                        </div>
                        <div class="col-sm-4">
                            <label class="user_info_header">{{$t('shared.district')}}</label>
                            <h4 class="user_info_txt">{{providerRequest.district}}</h4>
                        </div>
                        <div class="col-sm-4">
                            <label class="user_info_header">{{$t('shared.streetName')}}</label>
                            <h4 class="user_info_txt">{{providerRequest.streetName}}</h4>
                        </div>
                        <div class="col-sm-4">
                            <label class="user_info_header">{{$t('shared.buildingNumber')}}</label>
                            <h4 class="user_info_txt">{{providerRequest.buildingNumber}}</h4>
                        </div>
                        <div class="col-sm-4">
                            <label class="user_info_header">{{$t('shared.postalCode')}}</label>
                            <h4 class="user_info_txt">{{providerRequest.postalCode}}</h4>
                        </div>
                        <div class="col-sm-4">
                            <label class="user_info_header">{{$t('shared.additionalNumber')}}</label>
                            <h4 class="user_info_txt">{{providerRequest.additionalNumber}}</h4>
                        </div>
                        <div class="col-sm-4">
                            <label class="user_info_header">{{$t('shared.otherIdentifier')}}</label>
                            <h4 class="user_info_txt">{{providerRequest.otherIdentifier}}</h4>
                        </div>
                        <div class="col-sm-4">
                            <label class="user_info_header">{{$t('shared.vatNumber')}}</label>
                            <h4 class="user_info_txt">{{providerRequest.vatNumber}}</h4>
                        </div>
                        <div class="col-sm-4">
                            <label class="user_info_header">{{$t('dashboardPage.status')}}</label>
                            <h4 class="user_info_txt" :class="'status-' + providerRequest.providerRequestStatus">{{GetProviderRequestStatus(providerRequest.providerRequestStatus)}}</h4>
                        </div>
                        <div class="col-sm-4 mb-3">
                            <label class="user_info_header">{{$t('shared.attachDelegationLetter')}}</label>
                            <h4 class="user_info_txt">
                                <span @click="DownloadFile(providerRequest)" class="badge badge-primary">
                                    <i class="fas fa-paperclip"></i>{{providerRequest.fileName}}
                                    <i class="fas fa-arrow-down"></i>
                                </span>
                            </h4>
                        </div>
                        <hr class="w-100 mt-4" />
                        <div class="col-md-12">
                            <label class="card_header_text">{{$t('shared.users')}}</label>
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th class="text-center">{{$t('shared.userName')}}</th>
                                            <th class="text-center">{{$t('shared.userEmail')}}</th>
                                            <th class="text-center">{{$t('shared.phoneNumber')}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="text-center">{{providerRequest.username}}</td>
                                            <td class="text-center">{{providerRequest.useremail}}</td>
                                            <td class="text-center">{{providerRequest.mobile}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="col-md-12 mt-5">
                            <div class="form-row justify-content-end align-items-center">
                                <div class="col-auto mx-3">
                                    <router-link to="/Providers/ProviderRequests" tag="a" class="link_secondary router-link-active">{{$t('shared.back')}}</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

