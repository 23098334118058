import { Component, Vue } from 'vue-property-decorator';
import WithRender from './ReceivingAdvertisingCalls.html';
import ReceivingAdvertisingCallsService from './ReceivingAdvertisingCallsService';
import { Inject } from 'vue-di-container';
import { Toaster } from '../../Services/toast';
import moment from 'moment';
import TokenService from '../../Services/TokenService';
import ValidationService from '../../Services/ValidationService';
import { VueRecaptcha } from 'vue-recaptcha';

@WithRender
@Component({
    components: {
        VueRecaptcha,
    },
    diProvide: [
        ReceivingAdvertisingCallsService,
        ValidationService    ],
})
export default class ReceivingAdvertisingCalls extends Vue {
    @Inject(ReceivingAdvertisingCallsService) public receivingAdvertisingCallsService!: ReceivingAdvertisingCallsService;
    @Inject(ValidationService) public validationService!: ValidationService;
    @Inject(TokenService) public tokenService!: TokenService;
    public EnterMobileNumber: boolean = true;
    public isOTP: boolean = false;
    public AdvertisingCalls: boolean = false;
    public isOptions: boolean = false;
    public isOTPDone: boolean = false;
    public isNotemsg: boolean = false;
     public recaptchaVerified: boolean = false;
    public themeCompany: any = 'Default';
    public logoSrc: any = 'JabberLogo';
    public logoTextSrc: any = 'JabberLogo';
    public countDown: number = 0;
    public data: string = '';
    public otpExpiresAt: string = '';
    public otp: string = '';
    public phoneNumber: string = '';
    public isallow: boolean | undefined;
    public Options: number = 0;
    public recapchaResponse: string = '';
    public siteKey: string = '6Lc0grMdAAAAAPS0yk50bOg4XfNKuNcrg90fqOpz';
    public elementVisible: boolean = true;
    public Enter() {

        this.countDownTimer();
    }
    public created() {

        setTimeout(() => this.elementVisible = false, 2500);
       /* this.siteKey = window.$config.recaptchaSiteKey;*/
    }
    public verifyRecaptcha(response: any) {
        this.recapchaResponse = response;
    }
    public async sendOtp() {
        const result = await this.receivingAdvertisingCallsService.sendOtp(this.phoneNumber, this.recapchaResponse);
        if (result.isSuccess) {
            this.data = result.data;
            this.otpExpiresAt = result.otpResult.expiresAt;
            this.countDown = result.otpResult.duration;
            this.EnterMobileNumber = false;
            this.isOTP = true;
            this.countDownTimer();
        } else {
            const msg = this.$t(result.errorMessage).toString();
            Toaster.error(msg);
        }
    }
    public expiredRecaptcha() {
        this.recaptchaVerified = false;
    }

    public async verfiyOTP() {
        const result = await this.receivingAdvertisingCallsService.verfiyOTP(this.data, this.otpExpiresAt, this.otp, this.phoneNumber);
        if (result.isSuccess) {
            await this.tokenService.setToken(result.token);
            const expiresIn = parseInt(result.expiresIn, 0);
            localStorage.timer = expiresIn * 60;
            localStorage.expiresIn = moment().add(expiresIn, 'minutes').format('lll');
            this.EnterMobileNumber = false;
            this.isOTP = false;
            this.getallowCallRegistry();
            this.isOptions = true;
        } else {
            const msg = this.$t(result.errorMessage).toString();
            Toaster.error(msg);
        }
    }
    public async allowCallRegistry() {
        if (this.Options == 1) {
            this.isallow = true;
        } else {
            this.isallow = false;
        }
        const result = await this.receivingAdvertisingCallsService.allowCallRegistry(this.isallow);
        if (result.result) {
            this.isNotemsg = true;
        } else {
            this.isNotemsg = false;
        }
        this.isOptions = false;
        this.isOTPDone = true;
    }
    public async getallowCallRegistry() {
        const response = await this.receivingAdvertisingCallsService.getallowCallRegistry();
        this.isallow = response;
        if (this.isallow == true) {
            this.Options = 1;
        } else {
            this.Options = 2;
        }
    }
    public get isResendValid(): boolean {
        return (this.countDown > 0);
    }
    public async resendOtp() {
        const result = await this.receivingAdvertisingCallsService.resendOTP(this.data);
        if (result.isSuccess) {
            this.data = result.data;
            this.otpExpiresAt = result.otpResult.expiresAt;
            this.countDown = result.otpResult.duration;
            this.countDownTimer();
        } else {
            const msg = this.$t(result.errorMessage).toString();
            Toaster.error(msg);
        }
    }
    public countDownTimer() {
        if (this.countDown > 0) {
            setTimeout(() => {
                this.countDown -= 1;
                this.countDownTimer();
            }, 1000);
        }
    }
    public get currentYear() {
        return moment().year();
    }

    public get isValid(): boolean {
        return (
            this.phoneNumber.length === 10 && this.recapchaResponse !== ''
        );
    }
    public get isValidOTP(): boolean {
        return (
            this.otp.length >= 4
        );
    }

    public isNumberField(val: KeyboardEvent) {
        if (!this.validationService.isNumber(val.key)) {
            val.preventDefault();

        }
    }
    public isMobileNumberValid() {
        if (!this.phoneNumber.startsWith('05')) {
            Toaster.error(this.$t('shared.InvalidMobileNumber').toString());
        }
    }
}