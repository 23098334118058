<div class="w-100 h-100">
    <div class="row" style="padding-top: 25px;padding-bottom: 25px;">
        <div class="col-md-12">
            <label class="header_text mb-3" v-if="!isEditMode">
                {{$t('shared.newUser')}}
            </label>
            <label class="header_text mb-3" v-if="isEditMode">
                {{$t('shared.editUser')}}
            </label>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="card card_layout">
                <div class="card-body card_body_layout">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>{{$t('shared.userName')}}</label>
                                <div class="input-group mb-3">
                                    <input class="form-control"
                                            v-model="user.username" type="text" :disabled="isEditMode" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>{{$t('shared.userEmail')}}</label>
                                <div class="input-group mb-3">
                                    <input class="form-control" type="text"
                                             v-model="user.email" :disabled="isEditMode" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 mb-3">
                            <div class="form-group">
                                <label>{{$t('shared.phoneNumber')}}</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text gray">
                                            +966
                                        </span>
                                    </div>
                                    <input class="form-control border-left-0" @change="isMobileNumberValid" @paste="isNumberFieldPaste($event)" maxlength="9" @keypress="isNumberField($event)" v-model="user.mobileNumber" type="text" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4" v-if="UserRole !== 'Provider Admin'">
                            <div class="form-group">
                                <label>{{$t('shared.type')}}</label>
                                <v-select v-model="user.roleId"
                                          :items="typeOptions"
                                          item-text="name"
                                          item-value="id"
                                          @change="onChangeType"
                                          :label="$t('shared.selectType')"
                                          :readonly="isEditMode"
                                          solo>
                                </v-select>
                            </div>
                        </div>
                        <div class="col-md-4" v-if="UserRole === 'Provider Admin'">
                            <div class="form-group">
                                <label>{{$t('shared.type')}}</label>
                                <v-select v-model="user.roleId"
                                          :items="typeOptions"
                                          item-text="name"
                                          item-value="id"
                                          @change="onChangeType"
                                          :label="$t('shared.selectType')"
                                          solo>
                                </v-select>
                            </div>
                        </div>
                        <div class="col-md-4" v-if="user.roleId == 2 || user.roleId == 5">
                            <div class="form-group">
                                <label>{{$t('shared.providers')}}</label>
                                <v-select v-model="user.providerId"
                                          :items="providerOptions"
                                          item-text="name"
                                          item-value="id"
                                          :label="$t('shared.selectProvider')"
                                          :readonly="isEditMode"
                                          solo>
                                </v-select>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <v-switch v-model="user.isLocked"
                                      :label="user.isLocked? $t('shared.lock') : $t('shared.unLock')"></v-switch>
                        </div>
                        <div class="col-md-12 mt-5">
                            <div class="form-row justify-content-end align-items-center">
                                <div class="col-auto mx-3">
                                    <router-link to="/UserManagement" tag="a" class="link_secondary">{{$t('shared.cancel')}}</router-link>
                                </div>
                                <div class="col-auto" v-if="isEditMode">
                                    <button class="btn btn-success px-5 py-2"
                                            @click="showEditUser" :disabled="!isValid">
                                        {{$t('shared.save')}}
                                    </button>
                                </div>
                                <div class="col-auto" v-if="!isEditMode">
                                    <button class="btn btn-success px-5 py-2"
                                            @click="showAddUser" :disabled="!isValid">
                                        {{$t('shared.save')}}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="text-xs-center">
        <v-dialog v-model="editUserDialog" width="554">
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                    {{$t('shared.editInput')}}
                    <div class="close__dialog">
                        <i class="fas fa-times" @click="hideEditUserDialog"></i>
                    </div>
                </v-card-title>

                <v-card-text class="text-center">
                    <!--<img :src="require('../img/folder.svg')" />-->
                    <p class="mt-3">
                        {{$t('shared.editUserInputText')}}
                    </p>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <div class="form-row justify-content-end align-items-center">
                        <div class="col-auto mx-3">
                            <a class="link_danger" @click="hideEditUserDialog">
                                {{$t('shared.cancel')}}
                            </a>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-danger px-5 py-2" @click="editUser">{{$t('shared.Ok')}}</button>
                        </div>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
    <div class="text-xs-center">
        <v-dialog v-model="addUserDialog" width="554">
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                    {{$t('shared.addInput')}}
                    <div class="close__dialog">
                        <i class="fas fa-times" @click="hideAddUserDialog"></i>
                    </div>
                </v-card-title>

                <v-card-text class="text-center">
                    <!--<img :src="require('../img/folder.svg')" />-->
                    <p class="mt-3">
                        {{$t('shared.addUserInputText')}}
                    </p>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <div class="form-row justify-content-end align-items-center">
                        <div class="col-auto mx-3">
                            <a class="link_danger" @click="hideAddUserDialog">
                                {{$t('shared.cancel')}}
                            </a>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-danger px-5 py-2" @click="addUser">{{$t('shared.Ok')}}</button>
                        </div>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</div>

