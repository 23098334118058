<div class="w-100 h-100">
    <div class="row" style="padding-top: 56px;">
        <div class="col-md-12">
            <div class="form-row justify-content-between align-items-baseline">
                <div class="col-auto">
                    <label class="header_text mb-3">
                        {{$t('extendDurationRequestsPage.extendDurationRequests')}}
                    </label>
                </div>
            </div>
        </div>
    </div>
    <div class="row row-table">
        <div class="col-md-12">
            <div class="card card_layout">
                <div class="card-header">
                    <div class="form-row justify-content-between align-items-center w-100">
                        <div class="col-auto">
                            <label class="card_header_text">
                                <i class="fas fa-sync-alt reload" @click="reload()"></i>
                                {{$t('extendDurationRequestsPage.requests')}} ({{sendersCount}})
                            </label>
                        </div>
                        <div class="col-auto">
                            <button :disabled="!isAnySelected" class="btn btn-primary btn-action" @click="PreparePay('')">{{$t('extendDurationRequestsPage.pay')}}</button>
                            <button :disabled="isAnySelected" @click="renewRegisterSender()" class="btn btn-primary btn-action">{{$t('extendDurationRequestsPage.renew')}}</button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <template>
                        <div class="row">
                            <div class="col-md-3">
                                <div class="input-group mb-3">
                                    <input class="form-control border-right-0 border-left"
                                           type="text"
                                           v-model="filterModel.senderName"
                                           @keypress="search"
                                           :placeholder="$t('dashboardPage.senderNameSearch')" />
                                    <div class="input-group-append">
                                        <span class="input-group-text right gray">
                                            <i class="fas fa-search"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <v-data-table v-model="selected"
                                      :headers="headers"
                                      :items="senders"
                                      :total-items="sendersCount"
                                      select-all
                                      :pagination.sync="pagination"
                                      :rows-per-page-text="$t('shared.rowsPerPage')"
                                      :rows-per-page-items='[10, 25, 50, 100]'
                                      @update:pagination="updatePaginate"
                                      item-key="id"
                                      class="elevation-1 table-striped">
                            <template v-slot:headers="props">
                                <tr>
                                    <th>
                                        <v-checkbox :input-value="props.all"
                                                    :indeterminate="props.indeterminate"
                                                    primary
                                                    hide-details
                                                    @click.stop="selectAll"></v-checkbox>
                                    </th>
                                    <th v-for="header in props.headers"
                                        :key="header.text"
                                        :class="['column', header.sortable ? 'sortable':'', header.sortable ? pagination.descending ? 'desc' : 'asc': '', header.value === pagination.sortBy ? 'active' : '']"
                                        @click="changeSort(header.value)" :width="header.width !== ''? header.width : '' ">
                                        <v-icon v-if="header.sortable" small>arrow_upward</v-icon>
                                        {{ header.text }}
                                    </th>
                                </tr>
                            </template>
                            <template v-slot:items="props">
                                <tr>
                                    <td>
                                        <v-checkbox v-model="props.selected"
                                                    @change="select($event, props.item.id)"
                                                    primary
                                                    hide-details></v-checkbox>
                                    </td>
                                    <td class="text-left">{{ props.item.id }}</td>
                                    <td class="text-center">{{ props.item.senderName }}</td>
                                    <td class="text-center">{{ props.item.duration }}</td>
                                    <td class="text-center">{{ props.item.createdDate }}</td>
                                    <td class="text-center">{{ props.item.requestStatus }}</td>
                                    <td class="text-center">
                                        <button v-if="props.item.requestStatus =='Pending Payment'" @click="PreparePay(props.item.id)" class="btn btn-primary btn-action">{{$t('extendDurationRequestsPage.pay')}}</button>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </template>
                </div>
            </div>
        </div>
    </div>
</div>

