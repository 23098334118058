import AxiosService from '@/Services/AxiosService';
import LoaderService from '@/Services/LoaderService';
import { Inject, Service } from 'vue-di-container';
@Service()
export default class ChartDashboardService {
    @Inject(AxiosService) public axiosService!: AxiosService;
    @Inject(LoaderService) public loaderService!: LoaderService;

    public async Get(model: any): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/sender-requests';
            const result = await this.axiosService.axiosInstance.get(url, {
                params: model,
            });
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
  
    public async GetDashboardBoxes(): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/kpi-dashboard/chart-dashboard-boxes';
            const result = await this.axiosService.axiosInstance.get(url);
            this.loaderService.HideLoader();
            return result.data.result;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
    
    public async GetDashboardStatusBoxes(model: any): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/kpi-dashboard/chart-dashboard-status-boxes';
            const result = await this.axiosService.axiosInstance.get(url, {
                params: model,
            });
            this.loaderService.HideLoader();
            return result.data.result;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
    
    public async AllRequestsChart(model :any): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/kpi-dashboard/all-requests-chart';
            const result = await this.axiosService.axiosInstance.post(url, model);
            this.loaderService.HideLoader();
            return result.data.result;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    } 

    public async GetDefaultDates(): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/kpi-dashboard/get-default-dates';
            const result = await this.axiosService.axiosInstance.get(url);
            this.loaderService.HideLoader();
            return result.data.result;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    } 
    
    public async PendingApprovalAlerts(): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/kpi-dashboard/pending-approval-alerts';
            const result = await this.axiosService.axiosInstance.post(url);
            this.loaderService.HideLoader();
            return result.data.result;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }

    public async PendingActivationAlerts(): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/kpi-dashboard/pending-activation-alerts';
            const result = await this.axiosService.axiosInstance.post(url);
            this.loaderService.HideLoader();
            return result.data.result;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
    public async GetMonitorDashboardOptions(): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/kpi-dashboard/get-monitor-dashboard-options';
            const result = await this.axiosService.axiosInstance.post(url);
            this.loaderService.HideLoader();
            return result.data.result;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }

    public async PendingPaymentAlerts(): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/kpi-dashboard/pending-payment-alerts';
            const result = await this.axiosService.axiosInstance.post(url);
            this.loaderService.HideLoader();
            return result.data.result;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }   
}
