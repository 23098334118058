import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Inject } from 'vue-di-container';
import WithRender from './SenderConectivityDetail.html';
import { SenderConectivityDetailModel } from './SenderConectivityDetailModel';
import SenderConectivityDetailService from './SenderConectivityDetailService';
import { Toaster } from '../../Services/toast';
import { FilterModel } from './filterModel';
import { SenderConectivityStatus } from '../../shared/Enums/SenderConectivityStatus';
import { AddConectivityModel } from './addConectivityModel';
import { ShortModel } from '../Short/ShortModel';
import ShortService from '../Short/ShortService';
import { SenderInfoModel } from './senderInfoModel';
import { SenderType } from '../../shared/Enums/SenderType';
import UserService from '../../shared/userService/UserService';
import { SenderConectivityDetaillogModel } from './SenderConectivityDetaillogModel';
import UserRoleService from '../../shared/userService/UserRoleService';
import { ShortCodeType } from '../../shared/Enums/ShortCodeType';

@WithRender
@Component({
    components: {
    },
    diProvide: [
        SenderConectivityDetailService,
        ShortService,
        UserService,
        UserRoleService,
    ],
})
export default class SenderConectivityDetail extends Vue {
    @Inject(SenderConectivityDetailService) public senderConectivityService!: SenderConectivityDetailService;
    @Inject(ShortService) public shortService!: ShortService;
    @Inject(UserService) public userService!: UserService;
    @Inject(UserRoleService) public userRoleService!: UserRoleService;

    @Prop({ type: Boolean, default: false })
    public layoutSup: boolean | any;

    @Prop({ type: String, default: '' })
    public senderId: string | any;

    public isModeShow: boolean = false;
    public deleteDialog: boolean = false;
    public resendToOperatorDialog: boolean = false;
    public addDialog: boolean = false;
    public logDialog: boolean = false;
    public conectivityId: string = '';
    public senderType: SenderType[] = [];
    public isAlreadyExistEnabled: boolean = false;
    public User: any;
    public userRole: any;
    public headers: any = [
        { text: this.$t('shortPage.company'), value: 'operator', align: 'center', sortable: false },
        { text: this.$t('shortPage.shortCode'), value: 'shortCodeName', align: 'center', sortable: false },
        { text: this.$t('shared.senderType'), value: 'senderType', align: 'center', sortable: false },
        { text: this.$t('shared.conectivityStatus'), value: 'status', align: 'center', sortable: false },
        { text: this.$t('shared.resendtooperator'), sortable: false, value: 'id', align: 'center' },
        { text: this.$t('shared.actions'), sortable: false, value: 'id', align: 'center', width: '100' },
    ];

    public operatorOptions: any[] = [];
    public senderTypeOptions: any[] = [];
    public shortCodeOptions: ShortModel[] = [];
    public shortCode: ShortModel[] = [];
    public connectivities: SenderConectivityDetailModel[] = [];
    public filterConnectivities: SenderConectivityDetailModel[] = [];
    public filterModel: FilterModel = {
        operator: '',
        shortCodeName: '',
        status: 0,
    };

    public senderInfo: SenderInfoModel = {
        id: '',
        senderName: '',
        senderStatus: 0,
        senderType: 0,
        duration: 0,
        startDate: '',
        clientName: '',
        crNumber: '',
        customerType: 0,
        isAlreadyExist: false,
    };

    public addModel: AddConectivityModel = {
        operator: 0,
        senderId: '',
        shortCodeId: [],
        senderType: 0,
        isAlreadyExist: false,
    };
    public logModel: SenderConectivityDetaillogModel = {
        id: '',
        addRequest: '',
        addResponse: '',
        deleteRequest: '',
        deleteResponse: '',
    };
    public statusOptions: any[] = [
        {
            id: SenderConectivityStatus.PendingAddition,
            name: this.$t('shared.pendingAddition').toString(),
        },
        {
            id: SenderConectivityStatus.Added,
            name: this.$t('shared.added').toString(),
        },
        {
            id: SenderConectivityStatus.PendingDeletion,
            name: this.$t('shared.pendingDeletion').toString(),
        },
        {
            id: SenderConectivityStatus.Deleted,
            name: this.$t('shared.deleted').toString(),
        },
    ];
    public GetUserRole() {
        return this.userRoleService.GetUserRole(this.User);
    }
    public async created() {
        this.$emit('childinit', this.layoutSup);
        this.operatorOptions = window.$config.OperarorList;
    }

    public async mounted() {
        this.User = await this.userService.getUser();
        this.userRole = this.GetUserRole();
        const option = await this.senderConectivityService.getConnectivityConfigration();
        this.isAlreadyExistEnabled = option.result.isAlreadyExistEnabled;
        const response = await this.senderConectivityService.get(this.senderId);
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
        }
        this.connectivities = response.result;
        const responseSenderInfo = await this.senderConectivityService.getSenderInfo(this.senderId);
        this.senderInfo = responseSenderInfo.result;
        const responseShortCode = await this.shortService.getAll();
        this.shortCode = responseShortCode.result;
        this.search();
        this.getSenderType();
        let sumSenderType = 0;
        let sumSenderTypeText = '';
        this.senderType.filter((x) => x !== 1).forEach((x, index) => {
            sumSenderType += x;
            sumSenderTypeText += this.getSenderTypeText(x);
            if (this.senderType.filter((y) => y !== 1).length !== index + 1) {
                sumSenderTypeText += ' , ';
            }
        });
        if (this.senderType.filter((x) => x === 1).length !== 0) {
            this.senderTypeOptions.push({
                id: 1,
                name: this.getSenderTypeText(1),
            });
        }
        if (sumSenderType !== 0) {
            this.senderTypeOptions.push({
                id: sumSenderType,
                name: sumSenderTypeText,
            });
        }
    }

    @Watch('addModel.operator', { deep: true })
    public onchangeOperator() {
        this.filterShortCodeByOperator();
        this.filterShortCodeByType();
    }
    
    @Watch('addModel.senderType', { deep: true })
    public onchangeSenderType() {
        this.filterShortCodeByOperator();
        this.filterShortCodeByType();
    }

    @Watch('filterModel', { deep: true })
    public search() {
        this.filterConnectivities = this.connectivities.filter((r) => {
            return this.isEqual(this.filterModel.operator, this.getOperatorText(r.operator))
                && this.isEqual(this.filterModel.shortCodeName, r.shortCodeName)
                && (this.filterModel.status === 0 ||
                    this.filterModel.status === undefined ||
                    this.filterModel.status === null ||
                    this.filterModel.status === r.status);
        });
    }

    private filterShortCodeByType(){
        if (this.addModel.senderType === 0 || this.addModel.senderType === null) {
            return;
        }
        else if (this.addModel.senderType === SenderType.Advertisement) {
            this.shortCodeOptions = this.shortCodeOptions.filter((x) => x.shortCodeType === ShortCodeType.BlackList);
        }
        else {
            this.shortCodeOptions = this.shortCodeOptions.filter((x) => x.shortCodeType === ShortCodeType.WhiteList);
        }
    }

    private filterShortCodeByOperator(){
        this.shortCodeOptions = this.shortCode.filter((x) => x.operatorId === this.addModel.operator);
        this.addModel.shortCodeId = [];
    }

    public getOperatorText(operator: number) {
        switch (operator) {
            case 1:
                return this.$t('operator_Zain').toString();
            case 2:
                return this.$t('operator_STC').toString();
            case 3:
                return this.$t('operator_Mobily').toString();
            default:
                return '-';
        }
    }

    public getCustomerTypeText(typeId: number) {
        switch (typeId) {
            case 1:
                return this.$t('shared.government').toString();
            case 2:
                return this.$t('shared.charity').toString();
            case 3:
                return this.$t('shared.private').toString();
            case 4:
                return this.$t('shared.international').toString();
            default:
                return '-';
        }
    }

    public getConectivityStatusText(status: number) {
        switch (status) {
            case SenderConectivityStatus.PendingAddition:
                return this.$t('shared.pendingAddition').toString();
            case SenderConectivityStatus.Added:
                return this.$t('shared.added').toString();
            case SenderConectivityStatus.PendingDeletion:
                return this.$t('shared.pendingDeletion').toString();
            case SenderConectivityStatus.Deleted:
                return this.$t('shared.deleted').toString();
            default:
                return '-';
        }
    }

    public getSenderTypeText(typeId: number) {
        switch (typeId) {
            case SenderType.Advertisement:
                return this.$t('shared.advertisement').toString();
            case SenderType.Service:
                return this.$t('shared.service').toString();
            case SenderType.Awareness:
                return this.$t('shared.awareness').toString();
            case SenderType.Warning:
                return this.$t('shared.warning').toString();
            default:
                return '-';
        }
    }

    public getSenderType() {
        const senderBinary = this.senderInfo.senderType;

        if ((senderBinary & 1) === 1) {
            this.senderType.push(1);
        }
        if ((senderBinary & 2) === 2) {
            this.senderType.push(2);
        }
        if ((senderBinary & 4) === 4) {
            this.senderType.push(4);
        }
        if ((senderBinary & 8) === 8) {
            this.senderType.push(8);
        }
    }

    public getSenderTypeTextTable(senderType: number) {
        let senderTypeText = '';
        const senderTypeList = [];
        if ((senderType & 1) === 1) {
            senderTypeList.push(this.getSenderTypeText(1));
        }
        if ((senderType & 2) === 2) {
            senderTypeList.push(this.getSenderTypeText(2));
        }
        if ((senderType & 4) === 4) {
            senderTypeList.push(this.getSenderTypeText(4));
        }
        if ((senderType & 8) === 8) {
            senderTypeList.push(this.getSenderTypeText(8));
        }
        senderTypeText = senderTypeList.join(' / ');
        return senderTypeText;
    }


    public isEqual(value1: string | null, value2: string) {
        return (value1 === '' || value1 === null || value1 === undefined || value2.includes(value1));
    }

    public showDeleteDialog(id: string) {
        this.conectivityId = id;
        this.deleteDialog = true;
    }

    public showAddConectivityDialog() {
        this.addModel = {
            senderId: '',
            shortCodeId: [],
            operator: 0,
            senderType: 0,
            isAlreadyExist: this.senderInfo.isAlreadyExist,
        };
        this.addDialog = true;
    }

    public async showlogDialog(id: string) {
        const response = await this.senderConectivityService.getLogDetails(id);
        this.logModel = response.result;
        this.logDialog = true;
    }
    public hidelogDialog() {
        this.logModel = {
            id: '',
            addRequest: '',
            addResponse: '',
            deleteRequest: '',
            deleteResponse: '',
        };
        this.logDialog = false;
    }

    public hideDeleteDialog() {
        this.deleteDialog = false;
    }

    public hideAddDialog() {
        this.addDialog = false;
    }

    public showResendToOperatorDialog(id: string) {
        this.conectivityId = id;
        this.resendToOperatorDialog = true;
    }
    public hideResendToOperatorDialog() {
        this.resendToOperatorDialog = false;
    }

    public selectAllToggle() {
        this.$nextTick(() => {
            if (this.selectAllShortCode) {
                this.addModel.shortCodeId = [];
            } else {
                this.addModel.shortCodeId = this.shortCodeOptions.slice().map((x) => x.id);
            }
        });
    }

    public get selectAllShortCode() {
        return this.addModel.shortCodeId.length === this.shortCodeOptions.length;
    }

    public get selectSomeShortCode() {
        return this.addModel.shortCodeId.length > 0 && !this.selectAllShortCode;
    }

    public get icon() {
        if (this.selectAllShortCode) {
            return 'check_box';
        }

        if (this.selectSomeShortCode) {
            return 'indeterminate_check_box';
        }
        return 'check_box_outline_blank';
    }

    public save() {
        this.isModeShow = true;
    }

    public async addConectivity() {
        this.addModel.senderId = this.senderId;
        const response = await this.senderConectivityService.post(this.addModel);
        if (response.isSuccess && response.result) {
            Toaster.success(this.$t('shared.addConectivitySuccessfully').toString());
            this.addDialog = false;
            this.isModeShow = false;
            this.reload();
        } else {
            Toaster.error(response.errorMessage);
        }
    }

    public async resendToOperator() {
        const response = await this.senderConectivityService.markAsPendingAddition(this.conectivityId);
        if (response.isSuccess && response.result) {
            Toaster.success(this.$t('shared.resentSuccessfully').toString());
            this.reload();
        } else {
            Toaster.error(response.errorMessage);
        }
        this.resendToOperatorDialog = false;
    }

    public get isValid(): boolean {
        return (this.addModel.senderType !== 0 && this.addModel.senderType !== null
            && this.addModel.senderType !== undefined &&
            this.addModel.operator !== 0 && this.addModel.operator !== null
            && this.addModel.operator !== undefined && this.addModel.shortCodeId.length !== 0);
    }

    public async deleteConectivity() {
        const response = await this.senderConectivityService.delete(this.conectivityId);
        if (response.isSuccess && response.result) {
            Toaster.success(this.$t('shared.deleteConectivitySuccessfully').toString());
            this.reload();
        } else {
            Toaster.error(response.errorMessage);
        }
        this.deleteDialog = false;
    }


    public async reload() {
        const response = await this.senderConectivityService.get(this.senderId);
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
        }
        this.connectivities = response.result;
        this.search();
    }

    public back() {
        this.isModeShow = false;
    }
}
