import Progress from 'easy-circular-progress';
import { Inject } from 'vue-di-container';
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import LoaderService from '../../../Services/LoaderService';
import { Toaster } from '../../../Services/toast';
import ValidationService from '../../../Services/ValidationService';
import WithRender from './ExtendDurationRequests.html';
import { ExtendDurationRequestsFilterModel } from './ExtendDurationRequestsFilterModel';
import ExtendDurationRequestsService from './ExtendDurationRequestsService';
import { ExtendDurationRequestsModel } from './ExtendDurationRequestsModel';


@WithRender
@Component({
    components: {
        Progress,
    },
    diProvide: [
        ExtendDurationRequestsService,
    ],
})
export default class ExtendDurationRequests extends Vue {
    @Inject(ExtendDurationRequestsService) public extendDurationRequestsService!: ExtendDurationRequestsService;
    @Inject(LoaderService) public loaderService!: LoaderService;
    @Inject(ValidationService) public validationService!: ValidationService;
    @Prop({ type: Boolean, default: false })
    public layoutSup: boolean | any;
    public requestStatus: string = '';
    public senderStatus: string = '';
    public approveids: string[] = [];
    public approveDialog: boolean = false;
    public resendids: string[] = [];
    public resendDialog: boolean = false;
    public filterModel: ExtendDurationRequestsFilterModel = {
        senderName: '',
    };
    public userRole: any;
    public scrollDiv: any;
    public nextPage: string = '';
    public scrollTop: number = 0;
    public selected: ExtendDurationRequestsModel[] = [];
    public selectedIds: string[] = [];
    public ids: string[] = [];
    public senders: ExtendDurationRequestsModel[] = [];
    public sendersCount: number = 0;
    public User: any;
    public anySelected: boolean = false;
    public pagination: any = {
        sortBy: 'senderName',
    };
    public headers: any = [
        { text: this.$t('extendDurationRequestsPage.extendRequestId'), sortable: false, value: 'id', align: 'center' },
        { text: this.$t('extendDurationRequestsPage.senderName'), sortable: false, value: 'senderName', align: 'center' },
        { text: this.$t('extendDurationRequestsPage.extendDuration'), sortable: false, value: 'duration', align: 'left' },
        { text: this.$t('extendDurationRequestsPage.requestDate'), sortable: false, value: 'createdDate', align: 'center' },
        { text: this.$t('extendDurationRequestsPage.status'), sortable: false, value: 'requestStatus', align: 'center' },
        { text: this.$t('extendDurationRequestsPage.actions'), sortable: false, value: 'id', align: 'center', width: '145' },
    ];
    public changeSort(column: any) {
        if (this.pagination.sortBy === column) {
            this.pagination.descending = !this.pagination.descending;
        } else {
            this.pagination.sortBy = column;
            this.pagination.descending = false;
        }
    }
    public async mounted() {
        this.$emit('childinit', this.layoutSup);
        this.search();
    }
    @Watch('filterModel', { deep: true })
    public async search() {
        const model = {
            senderName: this.filterModel.senderName,
            pageIndex: '0',
            pagesize: '10',
        };
        const response = await this.extendDurationRequestsService.Get(model);
        if (response !== false) {
            if (!response.isSuccess) {
                this.$toast.error(response.errorMessage);
            }
        }
        this.senders = response.result;
        this.sendersCount = response.totalCount;
    }
    public isEqual(value1: string | null, value2: string) {
        return (value1 === '' || value1 === null || value2.includes(value1));
    }
    public selectAll() {
        if (this.selected.length) {
            this.selected = [];
            this.selectedIds = [];
        } else {
            this.selected = this.senders.slice();
            this.selectedIds = this.senders.map((x) => x.id);
        }
    }
    public select(event: any, id: string) {
        if (!event) {
            const index = this.selectedIds.indexOf(id);
            this.selectedIds.splice(index, 1);
        } else {
            this.selectedIds.push(id);
        }
    }
    public PreparePay(id: string) {
        let ids: string[] = [];
        if (id !== '') {
            ids.push(id);
        } else {
            ids = this.selectedIds;
            if (this.senders.filter((s) => ids.includes(s.id) && s.requestStatus !== 'Pending Payment')
                .length > 0) {
                Toaster.error(this.$t('dashboardPage.NotAllSenderPendingPay').toString());
                return;
            }
        }
        this.$router.push({ name: 'Payment', params: { ids: ids.join(',') } });
    }
    public async updatePaginate(val: any) {
        const model = {
            senderName: this.filterModel.senderName,
            pageIndex: (val.page - 1).toString(),
            pagesize: val.rowsPerPage,
        };
        const response = await this.extendDurationRequestsService.Get(model);
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
        }
        this.senders = response.result;
        this.sendersCount = response.totalCount;
    }
    public get isAnySelected(): boolean {
        this.anySelected = this.selectedIds.length > 0;
        return this.anySelected;
    }
    public async reload() {
        const model = {
            senderName: this.filterModel.senderName,
            pageIndex: '0',
            pagesize: '10',
        };
        const response = await this.extendDurationRequestsService.Get(model);
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
        }
        this.senders = response.result;
        this.sendersCount = response.totalCount;
        this.selectedIds = [];
        this.selected = [];
    }
    public async renewRegisterSender() {
        this.$router.push({ name: 'RenewRegisterSender' });
    }
}