import { Component, Vue, Prop } from 'vue-property-decorator';
import ValidationService from '../../Services/ValidationService';
import { Inject } from 'vue-di-container';
import WithRender from './Payment.html';
import PaymentService from './PaymentService';
import { PaymentModel } from './PaymentModel';
import MainPaymentComponents from '../MainPaymentComponents/MainPaymentComponents';
import { Toaster } from '../../Services/toast';
import MainPaymentService from '../MainPaymentComponents/MainPaymentService';
import { ProviderInvoiceInfoModel } from './ProviderInvoiceInfoModel';
import UserService from '../../shared/userService/UserService';
import { HyperPayModel } from './HyperPayModel';
import { InfoToCreateSadadNumber } from './InfoToCreateSadadNumberModel';

@WithRender
@Component({
    components: {
        MainPaymentComponents,
    },
    diProvide: [
        PaymentService,
        MainPaymentService,
        UserService,
    ],
})
export default class Payment extends Vue {
    public providerInvoiceInfo: ProviderInvoiceInfoModel = {
        country: '',
        city: '',
        district: '',
        streetName: '',
        buildingNumber: '',
        postalCode: '',
        vatNumber: '',
        additionalNumber: '',
        otherIdentifier: '',
    };
    @Inject(ValidationService) public validationService!: ValidationService;
    @Inject(PaymentService) public paymentService!: PaymentService;
    @Inject(MainPaymentService) public mainPaymentService!: MainPaymentService;
    @Inject(UserService) public userService!: UserService;
    @Prop({ type: Boolean, default: false })
    public layoutSup: boolean | any;
    @Prop({ type: Boolean, default: false })
    public isFromDashboard: boolean | any;
    public phoneNumber: string = '';
    public sadadNumber: string = '';
    public showSadadNumber: boolean = false;
    public sadadInfoAvailable: boolean = false;
    public disclaimerChecked: boolean = false;
    public ids: string | any;
    public idsList: string[] = [];
    public sadadNumbersToCancel: string[] = [];
    public sendersPaymentSummery: PaymentModel = {} as PaymentModel;
    public infoToCreateSadadNumber: InfoToCreateSadadNumber = {
        providerName: '',
        senderNames: '',
        issueDate: '',
        expireDate: '',
        expireDateTime: null,
        billNumber: '',
        providerEmail: '',
        vat: '',
        totalWithoutTax: '0',
    };
    public payfortModel: any;
    public expiryDate = new Date().toISOString().substr(0, 7);
    public cardNumber: string = '';
    public cVV: string = '';
    public cardHolderName: string = '';
    public exDate: string = '';
    public fellproviderinfodialog: boolean = false;
    public isCreatePaymentLinkOption: boolean = false;
    public isEfaaOption: boolean = false;
    public urlString: string = '';
    public showPaymentOptions: boolean = true;
    public isMada: boolean = false;
    public showPaymentLink: boolean = false;
    public showSadadOption: boolean = false;
    public User: any;
    public providerId: string = '';
    public paymentOptions: number = 0;
    public balanceAmount: number = 0;
    public isCreditLimitOptionValid: boolean = false;
    public checkoutId: string = '';
    public hyperPayModel: HyperPayModel = {} as HyperPayModel;
    public shopperResultUrl: string = '';
    public dataBrand: string = '';
    public $refs!: {

        payfortForm: HTMLFormElement,

        paymentMethod: HTMLFormElement,
    };
    public nameRules = [
        (v: any) => !!v || this.$t('sheared.itemisRequired'),
    ];
    public rulesExpiryDate = [
        (v: any) => v!! || this.$t('sheared.itemisRequired'),
    ];
    public paymentMethoditems = [
        { paymentMethodValue: 'VISA', paymentMethodName: 'VISA' },
        { paymentMethodValue: 'MASTERCARD', paymentMethodName: 'MASTERCARD' },
    ];
    public async mounted() {
        this.isCreatePaymentLinkOption = false;
        this.$emit('childinit', this.layoutSup);
        this.ids = this.$route.params.ids;
        if (this.ids.includes(',')) {
            this.idsList = this.ids.split(',');
        } else {
            this.idsList.push(this.ids);
        }
        const response = await this.paymentService.Get(this.idsList);
        if (response.isSuccess) {
            this.sendersPaymentSummery = response.result;
        }
        this.User = await this.userService.getUser();
        this.providerId = this.User.details.ProviderId;
        const balanceResponse = await this.paymentService.GetProviderBalance(this.providerId);
        this.balanceAmount = balanceResponse.result;
        const total = parseFloat(this.sendersPaymentSummery.total);
        this.isCreditLimitOptionValid = this.balanceAmount >= total;
        const userProviderhasVatNumber = await this.paymentService.providerHasVatNumber();
        if (userProviderhasVatNumber.isSuccess) {
            if (userProviderhasVatNumber.result === false) {
                await this.showfellproviderinfodialog();
                const infoResponse = await this.paymentService.getProviderNewInfo();
                this.providerInvoiceInfo = infoResponse.result;
            } else {
                await this.hidefellproviderinfodialog();
            }
        } else {
            Toaster.error(userProviderhasVatNumber.errorMessage);
        }
    }
    public async showfellproviderinfodialog() {
        this.fellproviderinfodialog = true;
    }
    public async hidefellproviderinfodialog() {
        this.fellproviderinfodialog = false;
    }
    public async hideOtherPaymentOptions() {
        this.showPaymentLink = false;
        this.showSadadOption = false;
    }
    public isNumberFieldPaste(ev: any) {
        const paste = (ev.clipboardData).getData('text');
        if (isNaN(paste)) {
            ev.preventDefault();
        }
    }
    public async Pay() {
        let idsList: string[] = [];
        if (this.ids.includes(',')) {
            idsList = this.ids.split(',');
        } else {
            idsList.push(this.ids);
        }
        await this.paymentService.Pay(idsList);
        Toaster.success(this.$t('dashboardPage.Paysuccessfully').toString());
        if (this.isFromDashboard) {
            this.$router.push({ name: 'Dashboard' });
        } else {
            this.$router.push({ name: 'ChangeSenderTypeRequests' });
        }
    }
    public async PayfortSubmit(event: any) {
        if (this.$refs.payfortForm.validate()) {
            const key = 'expiry_date';
            event.target.elements[key].value = this.GetExpireDate();
            const keyHolderName = 'card_holder_name';
            event.target.elements[keyHolderName].value = this.cardHolderName;
            const keyNumber = 'card_number';
            event.target.elements[keyNumber].value = this.cardNumber;
            const keycVV = 'card_security_code';
            event.target.elements[keycVV].value = this.cVV;
            event.target.action = this.payfortModel.GatewayUrl;
            event.target.submit();
        }
    }
    public GetExpireDate() {
        const date = this.expiryDate.split('-');
        const year = date[0].substr(2);
        const month = date[1];
        return year + month;
    }
    public isNumberField(val: KeyboardEvent) {
        if (!this.validationService.isNumber(val.key)) {
            val.preventDefault();
        }
    }
    public isDateField(val: KeyboardEvent) {
        if (!this.validationService.isDate(val.key)) {
            val.preventDefault();
        }
    }
    public updateValueCardNumber(e: any) {
        this.cardNumber = e.target.value.replace(/ /g, '');
    }
    public updateValueExDate(e: any) {
        this.exDate = e.target.value.replace(/\//g, '', '');
    }
    public get isValid(): boolean {
        return (this.expiryDate !== ''
            && this.expiryDate.includes('-')
            && this.cardNumber !== ''
            && this.cVV !== ''
            && this.cardHolderName !== '');
    }
    public get vatNumberIsValid(): boolean {
        return (this.providerInvoiceInfo.vatNumber !== null && this.providerInvoiceInfo.vatNumber !== '');
    }
    public async updateProvider() {
        if (this.providerInvoiceInfo.vatNumber !== '') {
            this.fellproviderinfodialog = false;
            const response = await this.paymentService.editProviderNewInfo(this.providerInvoiceInfo);
            if (!response.isSuccess) {
                Toaster.error(response.errorMessage);
            } else {
                Toaster.success(this.$t('shared.editProviderSuccessfully').toString());
                this.hidefellproviderinfodialog();
            }
        }
    }
    public async redirectToDashboard() {
        this.fellproviderinfodialog = false;
        this.$router.push({ name: 'Dashboard' });
    }
    public async createPaymentLinkClicked(): Promise<string> {
        this.urlString = window.location.origin + '/PublicPayment?ids=' + this.idsList + '&IsMada=' + this.isMada;
        return this.urlString;
    }
    public async copyURL() {
        await navigator.clipboard.writeText(this.urlString);
    }
    public get isOptionChooseValid(): boolean {
        return (this.paymentOptions > 0);
    }
    public async GotoPaymentOption() {
        if (this.paymentOptions.toString() === '1') {
            this.showPaymentOptions = false;
            this.isMada = false;
            const getHyperPayModelResponse = await this.paymentService.GetHyperPayModel
                (this.sendersPaymentSummery.total, this.isMada);
            if (getHyperPayModelResponse.isSuccess) {
                this.hyperPayModel = getHyperPayModelResponse.result;
                this.checkoutId = this.hyperPayModel.id;
                this.shopperResultUrl = this.hyperPayModel.shopperResultUrl;
                await this.paymentService.AddPaymentTransaction
                    (this.idsList, this.checkoutId, this.isMada, null, null);
                const script = document.createElement('script');
                script.setAttribute('src', this.sendersPaymentSummery.hyperPayUrl + this.checkoutId);
                const container = document.getElementById('hyperPaycontainer');
                container!.appendChild(script);
            }

        } else if (this.paymentOptions.toString() === '2') {
            this.showPaymentOptions = false;
            this.isMada = true;
            const getHyperPayModelResponse = await this.paymentService.GetHyperPayModel
                (this.sendersPaymentSummery.total, this.isMada);
            if (getHyperPayModelResponse.isSuccess) {
                this.hyperPayModel = getHyperPayModelResponse.result;
                this.checkoutId = this.hyperPayModel.id;
                this.shopperResultUrl = this.hyperPayModel.shopperResultUrl;
                await this.paymentService.AddPaymentTransaction
                    (this.idsList, this.checkoutId, this.isMada, null, null);
                const script = document.createElement('script');
                script.setAttribute('src', this.sendersPaymentSummery.hyperPayUrl + this.checkoutId);
                const container = document.getElementById('hyperPaycontainer');
                container!.appendChild(script);
            }
        } else if (this.paymentOptions.toString() === '3') {
            const addPaymentTransactionResponse = await this.paymentService.AddPaymentTransaction
                (this.idsList, this.checkoutId, this.isMada, this.sadadNumber, null);
            if (addPaymentTransactionResponse.isSuccess) {
                const response = await this.paymentService.SetCreditLimitTransactionAsPaid
                    (addPaymentTransactionResponse.result.paymentTransactionIds,
                        addPaymentTransactionResponse.result.paymentIdentifier);
                if (response.isSuccess) {
                    Toaster.success(this.$t('shared.paidSuccessfully').toString());
                    await this.paymentService.payRequestEmail(this.idsList);
                    this.sadadNumbersToCancel = response.result;
                    if (this.sadadNumbersToCancel.length !== 0) {
                        const cancelSadadNumbersResponse = await this.paymentService.CancelSadadNumbers(response.result);
                    }
                    this.$router.push({ name: 'Dashboard' });
                } else {
                    Toaster.error(response.errorMessage);
                }
            } else {
                Toaster.error(addPaymentTransactionResponse.errorMessage);
            }
        } else if (this.paymentOptions.toString() === '4') {
            this.isMada = false;
            this.showPaymentLink = true;
            this.isCreatePaymentLinkOption = true;
            this.createPaymentLinkClicked();
        } else if (this.paymentOptions.toString() === '5') {
            this.isMada = true;
            this.showPaymentLink = true;
            this.isCreatePaymentLinkOption = true;
            this.createPaymentLinkClicked();
        } else if (this.paymentOptions.toString() === '6') {
            if (this.sadadInfoAvailable === false) {
                const getInfoResponse = await this.paymentService.GetInfoToCreateSadadNumber(this.idsList);
                if (getInfoResponse.isSuccess) {
                    this.infoToCreateSadadNumber = getInfoResponse.result;
                    this.sadadInfoAvailable = true;
                } else {
                    Toaster.error(getInfoResponse.errorMessage);
                }
            }
            this.isMada = false;
            this.isEfaaOption = true;
            this.showSadadOption = true;
        }
    }
    public isMobileNumberValid() {
        if (!this.phoneNumber.startsWith('5')) {
            Toaster.error(this.$t('shared.InvalidMobileNumber').toString());
        }
    }
    public get isSadadOptionValid(): boolean {
        return (
            this.phoneNumber.trim() !== ''
            && this.phoneNumber.trim().length === 9
            && this.phoneNumber.startsWith('5')
            && this.disclaimerChecked === true
        );
    }
    public get isValidPhoneNumber(): boolean {
        return (
            this.phoneNumber.trim() !== ''
            && this.phoneNumber.trim().length === 9
            && this.phoneNumber.startsWith('5')

        );
    }
    public get disclaimerText(): string {
        return (this.$t('shared.sadadPaymentDisclaimer') + this.infoToCreateSadadNumber.providerName);
    }
    public get disclaimerTextArabic(): string {
        return (this.$t('shared.sadadPaymentDisclaimerArabic') + this.infoToCreateSadadNumber.providerName);
    }
    public async getSadadNumber() {
        const response = await this.paymentService.getSadadNumber
            (this.infoToCreateSadadNumber.providerName, this.phoneNumber,
                this.infoToCreateSadadNumber.issueDate, this.infoToCreateSadadNumber.expireDate,
                this.infoToCreateSadadNumber.senderNames, this.infoToCreateSadadNumber.totalWithoutTax,
                this.infoToCreateSadadNumber.billNumber,
                this.infoToCreateSadadNumber.providerEmail,
                this.infoToCreateSadadNumber.vat);
        if (response.isSuccess) {
            Toaster.success(this.$t('shared.sadadNumberCreatedSuccessfully').toString());
            this.sadadNumber = response.result;
            this.showSadadNumber = true;
            await this.paymentService.AddPaymentTransaction
                (this.idsList, this.checkoutId, this.isMada, this.sadadNumber, this.sendersPaymentSummery.expireDateTime);
        } else {
            Toaster.error(response.errorMessage);
        }
    }
}