<div class="w-100 h-100">

    <div class="row pt-25">
        <div class="col-12">
            <div class="card card_layout">
                <div class="card-header">
                    <div class="row justify-content-sm-center w-100">
                        <div class="col-sm-12">
                            <v-stepper alt-labels :value="stepNumber">
                                <v-stepper-header>
                                    <v-stepper-step step="1" :class="{'complete': stepNumber > 1}">{{$t('AddNewRequest.AddingNumbers')}}</v-stepper-step>
                                    <v-divider></v-divider>
                                    <v-stepper-step step="2" :class="{'complete': stepNumber > 2}">{{$t('AddNewRequest.Pay')}}</v-stepper-step>
                                </v-stepper-header>
                            </v-stepper>
                        </div>
                    </div>

                </div>

                <div class="row p-4" v-if="stepNumber == 1 && !showPayment">
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-5 mx-5">
                                <div class="form-group">
                                    <label>{{$t('AddNewRequest.ListOfNumbers')}}</label>
                                    <div class="input-group mb-3">
                                        <input class="form-control border-right-0 border-left" readonly v-model="fileNameListOfNumbers" type="text" />
                                        <input type="file" ref="fileListOfNumbers" hidden accept=".xlsx, .xls, .csv" @change="onFilePicked">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text right" style="padding: 2px 2px 0px 2px !important;">
                                                <i v-if="fileNameListOfNumbers != ''" class="far fa-trash-alt Icon_delete" @click="deleteListOfNumbers()"></i>
                                                <button class="btn btn-primary" @click="onPickFile(1)">{{$t('shared.attach')}}</button>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 mb-5">
                                <div class="col-md-5 mx-4">
                                    <ul>
                                        <li>{{$t('AddNewRequest.TheExcelFileFormat')}}</li>
                                        <li>{{$t('AddNewRequest.AddNumbersInFirstColumn')}}</li>
                                        <li>{{$t('AddNewRequest.TheMobileNumberFormat')}}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row p-3 mx-5" v-if="stepNumber == 2 && !showPayment">
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-6 border-right">
                                <label class="card_header_orders">{{$t('shared.orderSummary')}}</label>
                                <ul class="list-group list_orders">
                                    <li class="list-group-item d-flex justify-content-between align-items-end">
                                        <div class="order_text">
                                            <label class="order_title mb-0"></label>
                                            <h5 class="order_sub_title">{{FileName}}</h5>
                                        </div>
                                        <label class="order_cost">{{AmountWithoutTax}} SAR</label>
                                    </li>
                                </ul>
                                <div class="d-flex justify-content-between align-items-center">
                                    <label class="order_total">{{$t('shared.total')}}</label>
                                    <label class="order_total text-right">{{Amount}} SAR</label>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label class="card_header_orders">{{$t('shared.PaymentOptions')}} :</label>
                                <div calss="row" v-if="!showPayment">
                                    <input type="radio" value="1" name="Options" id="CreditCard" v-model="paymentOptions" />
                                    <label for="CreditCard" style="padding: 10px;">{{$t('shared.creditCardVisaMastercard')}}</label>
                                </div>
                                <div calss="row">
                                    <input type="radio" value="2" name="Options" id="DebitCardMada" v-model="paymentOptions" />
                                    <label for="DebitCardMada" style="padding: 10px;">{{$t('shared.debitCardMada')}}</label>
                                </div>

                                <div class="col-auto pt-5">
                                    <button class="btn btn-success px-5 py-2" @click="GotoPaymentOption" :disabled="!isOptionChooseValid">{{$t('shared.Go')}}</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="row p-3 mx-5" v-if="showPayment">
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-6 border-right">
                                <label class="card_header_orders">{{$t('shared.orderSummary')}}</label>
                                <ul class="list-group list_orders">
                                    <li class="list-group-item d-flex justify-content-between align-items-end">
                                        <div class="order_text">
                                            <label class="order_title mb-0"></label>
                                            <h5 class="order_sub_title">{{FileName}}</h5>
                                        </div>
                                        <label class="order_cost">{{AmountWithoutTax}} SAR</label>
                                    </li>
                                </ul>
                                <div class="d-flex justify-content-between align-items-center">
                                    <label class="order_total">{{$t('shared.total')}}</label>
                                    <label class="order_total text-right">{{Amount}} SAR</label>
                                </div>
                            </div>

                                <div id="hyperPaycontainer" v-if="showPayment && !isMada" class="col-md-6">
                                    <v-form :action="shopperResultUrl"
                                            name="hyperPayForm"
                                            class="paymentWidgets"
                                            data-brands="VISA MASTER"></v-form>
                                </div>
                                <div id="hyperPaycontainer" v-if="showPayment && isMada" class="col-md-6">
                                    <v-form :action="shopperResultUrl"
                                            name="hyperPayForm"
                                            class="paymentWidgets"
                                            data-brands="MADA"></v-form>
                                </div>

                        </div>
                    </div>

                </div>

                <div class="card-footer">
                    <div class="form-row justify-content-between align-items-center">
                        <div class="col-auto mx-3">
                            <button class="btn btn-primary px-5 py-2" :disabled="!isValid"  @click="SaveFile" v-if="stepNumber == 1 && !isEditMode">{{$t('shared.save')}}</button>
                            <button class="btn btn-primary px-5 py-2" :disabled="!isValid"  @click="isEditSaveFile" v-if="stepNumber == 1 && isEditMode">{{$t('shared.save')}}</button>
                            <!--<button class="btn btn-outline-primary px-5 py-2 mx-2" @click="back" v-if="stepNumber == 2 && !ispayMode">{{$t('shared.back')}}</button>-->
                        </div>
                        <div class="col-auto">

                            <button class="btn btn-primary px-5 py-2" :disabled="!isValid" @click="submitFile" v-if="stepNumber == 1 && !isEditMode">{{$t('shared.next')}}</button>
                            <button class="btn btn-primary px-5 py-2" :disabled="!isValid" @click="isEditsubmitFile" v-if="stepNumber == 1 && (isEditMode || ispayMode )">{{$t('shared.next')}}</button>
                            <!--<button class="btn btn-primary px-5 py-2" v-if="stepNumber == 2">{{$t('shared.submit')}}</button>-->

                        </div>
                    </div>
                </div>

            </div>

        </div>

    </div>
</div>



