<div class="w-100 h-100">
    <div class="row">
        <div class="col-md-12 pr-0">
            <div class="card card_layout">
                <div class="card-body">
                    <div class="col-md-12">
                        <div class="form-row justify-content-between align-items-baseline">
                            <div class="col-auto">
                                <div class="col-auto">
                                    <label v-if="status === '1'" class="header_text_black mb-3">
                                        {{$t('shared.requestsAlertsPendingApprovalRequests')}}
                                    </label>
                                    <label v-if="status === '2'" class="header_text_black mb-3">
                                        {{$t('shared.requestsAlertsPendingPaymentRequests')}}
                                    </label>
                                    <label v-if="status === '3'" class="header_text_black mb-3">
                                        {{$t('shared.requestsAlertsPendingActivation')}}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3 mx-1">
                        <div class="col-md-3">
                            <div class="input-group input-group-unstyled">
                                <input type="text" class="form-control-search" @keypress="updatePaginate" v-model="searchText" placeholder="search for request" />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <input type="checkbox" id="All" @change="selectAll" value="0" v-model="allChecked" class="mx-1">
                            <label class="mr-3 black--text" for="All"> {{$t('shared.all')}}</label>
                            <input type="checkbox" id="firstDuration" @change="updateSelect" value="1" v-model="firstDurationChecked" class="mx-1">
                            <label class="mr-3 black--text" for="firstDuration"> {{days[0]}} {{$t('days')}}</label>
                            <input type="checkbox" id="secondDuration" @change="updateSelect" value="2" v-model="secondDurationChecked" class="mx-1">
                            <label class="mr-3 black--text" for="secondDuration">{{days[1]}} {{$t('days')}}</label>
                            <input type="checkbox" id="thirdDuration" @change="updateSelect" value="3" v-model="thirdDurationChecked" class="mx-1">
                            <label class="mr-3 black--text" for="thirdDuration"> {{days[2]}} {{$t('days')}}</label>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="input-group input-group-unstyled">
                                    <input type="checkbox" id="custom" @change="selectCustom" value="4" v-model="customChecked" class="mx-1">
                                    <label class="mr-3 black--text" for="custom"> {{$t('Custom')}}</label>
                                    <input type="text" class="form-control-search" :disabled="!customChecked" @paste="isNumberFieldPaste($event)" v-model="customDay" placeholder="Custom Days" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3 mx-1 pt-4">
                        <div class="col-md-2 mb-3">
                            <v-menu v-model="createdDateFromMenu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    lazy
                                    transition="scale-transition"
                                    offset-y
                                    full-width
                                    min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field v-model="dateFrom"
                                                  label="From"
                                                  prepend-icon="event"
                                                  dense
                                                  solo
                                                  height="10"
                                                  class="soled-date-picker rounded-pill shadow p-1 mb-3 bg-white border-blue-dooted bg-white"
                                                  readonly
                                                  v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="dateFrom"
                                               @input="selectDateFrom"
                                               no-title
                                               scrollable></v-date-picker>
                            </v-menu>
                        </div>
                        <div class="col-md-2 mb-5 hi">
                            <v-menu v-model="createdDateToMenu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    lazy
                                    transition="scale-transition"
                                    offset-y
                                    full-width
                                    min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field v-model="dateTo"
                                                  label="To"
                                                  prepend-icon="event"
                                                  dense
                                                  solo
                                                  height="10"
                                                  class="soled-date-picker rounded-pill shadow p-1 mb-3 bg-white border-blue-dooted bg-whit"
                                                  readonly
                                                  v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="dateTo"
                                               @input="selectDateTo"
                                               no-title
                                               scrollable></v-date-picker>
                            </v-menu>
                        </div>
                        <div class="col-auto fa-pull-right">
                            <button class="btn btn-primary btn-action"
                                    @click="exportToExcel">
                                {{$t('shared.exporttoexcel')}}
                            </button>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <template>
                            <v-data-table :headers="headers"
                                          :items="requests"
                                          :total-items="requestsCount"
                                          :pagination.sync="pagination"
                                          @update:pagination="updatePaginate()"
                                          :rows-per-page-text="$t('shared.rowsPerPage')"
                                          :rows-per-page-items='[10, 25, 50, 100]'
                                          class="elevation-header ">
                                <template v-slot:items="props">
                                    <tr>
                                        <td class="text-center elevation-color-td">{{ props.item.id }}</td>
                                        <td class="text-center elevation-color-td">{{ props.item.senderName }}</td>
                                        <td class="text-center elevation-color-td">{{ props.item.clientName }}</td>
                                        <td class="text-center elevation-color-td">{{ props.item.providerName }}</td>
                                        <td class="text-center elevation-color-td">{{ getCustomerTypeText(props.item.customerType) }}</td>
                                        <td class="text-center elevation-color-td">{{ props.item.createdDate }}</td>
                                        <td class="text-center elevation-color-td">
                                            <div class="d-flex justify-content-center">
                                                <div class="img">
                                                    <img :src="require('../img/MobilyActive.svg')" v-if="getActivation(3, props.item.requestStatus,props.item.senderConectivityDetail)" />
                                                    <img :src="require('../img/MobilyDisabeld.svg')" v-if="!getActivation(3, props.item.requestStatus,props.item.senderConectivityDetail)" />
                                                </div>
                                                <div class="img">
                                                    <img :src="require('../img/STCActive.svg')" v-if="getActivation(2, props.item.requestStatus,props.item.senderConectivityDetail)" />
                                                    <img :src="require('../img/STCDisabeld.svg')" v-if="!getActivation(2, props.item.requestStatus,props.item.senderConectivityDetail)" />
                                                </div>
                                                <div class="img">
                                                    <img :src="require('../img/ZainActive.svg')" v-if="getActivation(1, props.item.requestStatus,props.item.senderConectivityDetail)" />
                                                    <img :src="require('../img/ZainDisabeld.svg')" v-if="!getActivation(1, props.item.requestStatus,props.item.senderConectivityDetail)" />
                                                </div>
                                            </div>
                                        </td>
                                        <td class="text-center elevation-color-td">{{ props.item.alerts }}</td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>