import { Service, Inject } from 'vue-di-container';
import AxiosService from '@/Services/AxiosService';
import LoaderService from '@/Services/LoaderService';
import { ProviderModel } from './ProviderModel';

@Service()
export default class ProviderService {
    @Inject(AxiosService) public axiosService!: AxiosService;
    @Inject(LoaderService) public loaderService!: LoaderService;

    public async Get(
        name: string, crNumber: string, accreditationNumber: string, taxNumber: string,
        pageIndex: string, pageSize: string): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/providers?Name=' + name
                + '&CrNumber=' + crNumber
                + '&AccreditationNumber=' + accreditationNumber
                + '&TaxNumber=' + taxNumber
                + '&pageIndex=' + pageIndex
                + '&pageSize=' + pageSize;
            const result = await this.axiosService.axiosInstance.get(url);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return [];
        }
    }
    public async Delete(Id: string): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/providers';

            const result = await this.axiosService.axiosInstance.delete(url,
                { params: { id: Id } });
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
    public async Post(model: ProviderModel): Promise<any> {
        this.loaderService.ShowLoader();
        const request = {
            crNumber: model.crNumber,
            name: model.name,
            createdDate: new Date(),
            accreditationNumber: model.accreditationNumber,
            email: model.email,
            taxNumber: model.taxNumber,
            country: model.country,
            city: model.city,
            district: model.district,
            streetName: model.streetName,
            buildingNumber: model.buildingNumber,
            postalCode: model.postalCode,
            vatNumber: model.vatNumber,
            additionalNumber: model.additionalNumber,
            otherIdentifier: model.otherIdentifier,
        };
        try {
            const url = window.$config.HostName + 'api/providers';
            const result = await this.axiosService.axiosInstance.post(url, request);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
    public async Put(model: ProviderModel): Promise<any> {
        this.loaderService.ShowLoader();
        const request = {
            id: model.id,
            name: model.name,
            crNumber: model.crNumber,
            createdDate: new Date(),
            accreditationNumber: model.accreditationNumber,
            email: model.email,
            country: model.country,
            city: model.city,
            district: model.district,
            streetName: model.streetName,
            buildingNumber: model.buildingNumber,
            postalCode: model.postalCode,
            vatNumber: model.vatNumber,
            additionalNumber: model.additionalNumber,
            otherIdentifier: model.otherIdentifier,
            hasInternational: model.hasInternational,
        };
        try {
            const url = window.$config.HostName + 'api/providers';
            const result = await this.axiosService.axiosInstance.put(url, request);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
    public async GetById(id: string): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/providers/' + id;
            const result = await this.axiosService.axiosInstance.get(url);
            this.loaderService.HideLoader();
            return result.data.result;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
    public async SwitchLock(id: string, isLocked: boolean): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            let url;
            if (isLocked) {
                url = window.$config.HostName + 'api/providers/lock?id=' + id;
            } else {
                url = window.$config.HostName + 'api/providers/unlock?id=' + id;
            }
            const result = await this.axiosService.axiosInstance.put(url);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
}
