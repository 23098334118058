import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import WithRender from './AlertsDashboard.html';
import { Toaster } from '../../Services/toast';
import AlertsDashboardServiceService from './AlertsDashboardService';
import { Inject } from 'vue-di-container';
import { SenderRequestKpiViewModel } from '../SenderRequestStatusDashboard/SenderRequestKpiViewModel';
import { filterModel } from './FilterModel';
import LoaderService from '../../Services/LoaderService';
import FileSaver from 'file-saver';
import ValidationService from '../../Services/ValidationService';

@WithRender
@Component({
    components: {
    },
    diProvide: [
        AlertsDashboardServiceService
    ],
})
export default class AlertsDashboard extends Vue {
    @Inject(AlertsDashboardServiceService) public alertsDashboardService!: AlertsDashboardServiceService;
    @Inject(ValidationService) public validationService!: ValidationService;
    @Inject(LoaderService) public loaderService!: LoaderService;
    @Prop({ type: Boolean, default: false })

    public headers: any = [
        { text: this.$t('shared.requestId'), value: 'requestId', align: 'center', class: "white--text", sortable: false },
        { text: this.$t('shared.sender'), value: 'sender', align: 'center', class: "white--text", sortable: false },
        { text: this.$t('shared.client'), value: 'client', align: 'center', class: "white--text", sortable: false },
        { text: this.$t('shared.provider'), value: 'provider', align: 'center', class: "white--text", sortable: false },
        { text: this.$t('shared.entityType'), value: 'entityType', align: 'center', class: "white--text", sortable: false },
        { text: this.$t('shared.date'), value: 'date', align: 'center', class: "white--text", sortable: false },
        { text: this.$t('dashboardPage.operatorStatus'), value: 'operatorStatus', align: 'center', class: "white--text", sortable: false },
        { text: this.$t('shared.alerts'), value: 'alerts', align: 'center', class: "white--text", sortable: false },
    ];
    public days: number[] = [];
    public selectedDays: any = [];
    public requests: SenderRequestKpiViewModel[] = [];
    public requestsCount: number = 0;
    public daysSelected: number[] = [];
    public allChecked: boolean = true;
    public customChecked: boolean = true;
    public isAllSelected: boolean = true;
    public firstDurationChecked: boolean = true;
    public secondDurationChecked: boolean = true;
    public thirdDurationChecked: boolean = true;
    public createdDateFromMenu: boolean = false;
    public createdDateToMenu: boolean = false;
    public dateFrom: string = '';
    public dateTo: string = '';

    public pagination: any;
    model: filterModel = {
        searchText: "",
        days: [],
        customDay: "0",
        pageIndex: 0,
        pageSize: 10,
        type: 0,
        isAllSelected: true,
        dateFrom: '',
        dateTo: '',
    };
    searchText: string = "";
    customDay: string = "";
    public status: string = '';
    allSelected: boolean = false;
    public async mounted() {
        this.status = this.$route.params.status;
        if (this.status == '1' || this.status == '2' || this.status == '3') {
            const getDaysResponse = await this.alertsDashboardService.getDaysConfiguration(Number(this.status));
            if (!getDaysResponse.isSuccess) {
                Toaster.error(getDaysResponse.errorMessage);
            } else {
                this.days = getDaysResponse.result;
            }
        }
        this.updatePaginate();
    }
    public async exportToExcel() {
        this.loaderService.ShowLoader();
        this.model.pageSize = 2147483647;
        const response = await this.alertsDashboardService.exportToExcel
            (this.model);
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
            this.loaderService.HideLoader();
        } else {
            const array = Buffer.from(response.result, 'base64');
            const blob = new Blob([array], { type: 'application/vnd.ms-excel' });
            FileSaver.saveAs(blob, 'AlertsReport.xlsx');
            this.loaderService.HideLoader();
        }
    }
    public async selectDateFrom() {
        this.createdDateFromMenu = false;
        this.updatePaginate();
    }
    public async selectDateTo() {
        this.createdDateToMenu = false;
        this.updatePaginate();
    }

    @Watch('customDay', { deep: true })
    public async searchForCustomDay() {
        if (this.customChecked) {
            if (this.customDay === "") {
                this.model.customDay = '0';
                this.updatePaginate();
            } else if ((this.validationService.isNumeric(this.customDay))) {
                if (Number(this.customDay) >= this.days[0]) {
                    this.model.customDay = this.customDay;
                    this.updatePaginate();
                } else {
                    Toaster.error(this.$t('shared.customDayMustBeLargerThanTheEnteredValue').toString());
                }
            } else {
                Toaster.error(this.$t('shared.customDaySearchMustBeUsingANumber').toString());
            }
        }
    }
    public async updatePaginate() {
        this.status = this.$route.params.status;
        if (this.status == '1' || this.status == '2' || this.status == '3') {
            this.model = {
                searchText: this.searchText,
                days: this.daysSelected,
                type: Number(this.status),
                customDay: this.customChecked === true ? this.customDay : "0",
                pageIndex: (this.pagination.page - 1),
                pageSize: this.pagination.rowsPerPage,
                isAllSelected: this.isAllSelected,
                dateFrom: this.dateFrom,
                dateTo: this.dateTo,
            };
            const response = await this.alertsDashboardService.getRequests(this.model);
            if (!response.isSuccess) {
                Toaster.error(response.errorMessage);
            } else {
                this.requests = response.result;
                this.requestsCount = response.totalCount;
            }
        } else {
            this.requests = [];
            this.requestsCount = 0;
            Toaster.error(this.$t('shared.sorryUnrecognizedRequestStatus').toString());
        }
    }
    public getActivation(operatorId: string, requestStatus: string, senderConectivityDetail: any[]) {
        let isActive = false;
        if (this.status == '3') {
            return false
        }
        if (requestStatus == '4') {
            const arrayForthisOperator = senderConectivityDetail
                .filter((a) => a.operator === operatorId
                    && (a.status === 2 || a.status === 1)).length;
            const approvedArrayForthisOperatorApproved = senderConectivityDetail
                .filter((a) => a.operator === operatorId && a.status === 2).length;
            if (approvedArrayForthisOperatorApproved > 0) {
                isActive = true;
            }
        }
        return isActive;
    }

    public isNumberFieldPaste(ev: any) {
        const paste = (ev.clipboardData).getData('text');
        if (isNaN(paste)) {
            ev.preventDefault();
        }
    }
    public getCustomerTypeText(typeId: number) {
        switch (typeId) {
            case 1:
                return this.$t('shared.government').toString();
            case 2:
                return this.$t('shared.charity').toString();
            case 3:
                return this.$t('shared.private').toString();
            case 4:
                return this.$t('shared.international').toString();
            default:
                return '-';
        }
    }
    public selectAll() {
        if (this.allChecked == true) {
            this.firstDurationChecked = true;
            this.secondDurationChecked = true;
            this.thirdDurationChecked = true;
            this.isAllSelected = true;
            this.allChecked = true;
            this.customChecked = true
        } else {
            this.firstDurationChecked = false;
            this.secondDurationChecked = false;
            this.thirdDurationChecked = false;
            this.isAllSelected = false;
            this.allChecked = false;
            this.customChecked = false
        }
        this.updatePaginate();
    }

    public selectCustom() {
        if (this.customChecked == true) {
            this.firstDurationChecked = false;
            this.secondDurationChecked = false;
            this.thirdDurationChecked = false;
            this.isAllSelected = false;
            this.allChecked = false;
        } else {
            this.firstDurationChecked = true;
            this.secondDurationChecked = true;
            this.thirdDurationChecked = true;
            this.isAllSelected = true;
            this.allChecked = true;
        }
        this.updatePaginate();
    }

    public updateSelect() {
        this.daysSelected = [];
        this.allChecked = false;
        if (this.firstDurationChecked == true) {
            this.daysSelected.push(this.days[0]);
        }
        if (this.secondDurationChecked == true) {
            this.daysSelected.push(this.days[1]);
        }
        if (this.thirdDurationChecked == true) {
            this.daysSelected.push(this.days[2]);
        }
        if (this.firstDurationChecked === false || !this.secondDurationChecked === false ||
            !this.thirdDurationChecked === false) {
            this.isAllSelected = false;
            this.allChecked = false;
        }
        this.updatePaginate();
    }
}