import { Component, Vue } from 'vue-property-decorator';
import WithRender from './MarketerSignUp.html';
import { Inject } from 'vue-di-container';
import MarketerSignUpService from './MarketerSignUpService';
import { MarketerSignUpModel } from './MarketerSignUpModel';
import { Toaster } from '../../Services/toast';
import ValidationService from '../../Services/ValidationService';
import moment from 'moment';

@WithRender
@Component({
    components: {
    },
    diProvide: [
        MarketerSignUpService
    ],
})
export default class MarketerSignUp extends Vue {
    @Inject(MarketerSignUpService) public marketerSignUpService!: MarketerSignUpService;
    @Inject(ValidationService) public validationService!: ValidationService;

    public model: MarketerSignUpModel = {
        username: '',
        password: '',
        rePassword: '',
        mobileNumber: '',
        name: '',
        crNumber: '',
        email: '',
        country: '',
        city: '',
        district: '',
        streetName: '',
        buildingNumber: '',
        postalCode: '',
        taxNumber: '',
        nationalAddress: ''
    };
    public get isValid(): boolean {
        return (
            this.model.name.trim() !== '' &&
            this.model.email.trim() !== '' &&
            this.model.password.trim() !== '' &&
            this.model.crNumber.trim() !== '' &&
            this.model.rePassword !== '' &&
            this.model.username.trim() !== '' &&
            this.model.nationalAddress !== '' &&
            this.model.mobileNumber.trim() !== '' &&
            this.model.mobileNumber.startsWith('5') &&
            this.model.mobileNumber.trim().length === 9 &&
            this.model.taxNumber.trim() !== '' &&
            this.model.postalCode.trim() !== '' &&
            this.model.buildingNumber.trim() !== '' &&
            this.model.streetName.trim() !== '' &&
            this.model.district.trim() !== '' &&
            this.model.city.trim() !== '' &&
            this.model.country.trim() !== '' 
        );
    }
    public async addMarketerRequest() {
        this.model.name.trim();
        this.model.password.trim();
        this.model.rePassword.trim();
        this.model.mobileNumber.trim();
        this.model.crNumber.trim();
        this.model.taxNumber.trim();
        this.model.email.trim();
        this.model.username.trim();
        this.model.country.trim();
        this.model.city.trim();
        this.model.district.trim();
        this.model.streetName.trim();
        this.model.buildingNumber.trim();
        this.model.postalCode.trim();
        this.model.taxNumber.trim();
        this.model.nationalAddress.trim();

        const response = await this.marketerSignUpService.advertiser(this.model);
        if (response.isSuccess) {
            Toaster.success(this.$t('userManagementPage.requestSubmittedSuccessfully').toString());
            this.$router.push({ name: 'Login' });
        } else {
            Toaster.error(response.errorMessage);
        }
    }
    public isNumberField(val: KeyboardEvent) {
        if (!this.validationService.isNumber(val.key)) {
            val.preventDefault();

        }
    }
    public isMobileNumberValid() {
        if (!this.model.mobileNumber.startsWith('5')) {
            Toaster.error(this.$t('shared.InvalidMobileNumber').toString());
        }
    }
    public isNumberFieldPaste(ev: any) {
        const paste = (ev.clipboardData).getData('text');
        if (isNaN(paste)) {
            ev.preventDefault();
        }

    }
    public get currentYear() {
        return moment().year();
    }
}