import { ref } from 'vue';
import { Inject } from 'vue-di-container';
import { Component, Vue } from 'vue-property-decorator';
import { Toaster } from '../../Services/toast';
import { HyperPayModel } from '../Payment/HyperPayModel';
import { PaymentModel } from '../Payment/PaymentModel';
import PaymentService from '../Payment/PaymentService';
import WithRender from './AddNewRequest.html';
import AddNewRequestService from './AddNewRequestService';
import { AdvertiserAttachmentModel } from './AdvertiserAttachmentModel';
import { AdvertiserRequestModel } from './AdvertiserRequestModel';

@WithRender
@Component({
    components: {
    },
    diProvide: [
        AddNewRequestService,
        PaymentService
    ],
})
export default class AddNewRequest extends Vue {
    @Inject(AddNewRequestService) public addNewRequestService!: AddNewRequestService;
    @Inject(PaymentService) public paymentService!: PaymentService;
    public stepNumber: number = 1;
    public paymentOptions: number = 0;
    public showPayment: boolean = false;
    public fileNameListOfNumbers: string = '';
    public typeFile: number = 0;
    public isMada: boolean = false;
    public resultID: any;
    public backwithId: any;
    public HyperPayUrl: string = '';
    public FileName: string = '';
    public AmountWithoutTax: number = 0;
    public Amount: number = 0;
    public sendersPaymentSummery: PaymentModel = {} as PaymentModel;
    public model: AdvertiserRequestModel = {
        file: []
    };
    public isEditMode: boolean = false;
    public fileName: string | any;
    public EditfileName: string | any;
    public EditContent: string | any;
    public FilePicked: boolean = false;
    public ispayMode: boolean = false;
    public id: string | any;
    public mode: string | any;
    public checkoutId: string = '';
    public shopperResultUrl: string = '';
    public hyperPayModel: HyperPayModel = {} as HyperPayModel;
    public async submitFile() {
        let model = {
            content: this.model.file[0].Content,
            fileName: this.model.file[0].FileName
        }
        const response = await this.addNewRequestService.post( model);
        if (response.isSuccess) {
       
            this.resultID = response.result;
            this.AdvertiserRequestPaymentTransaction();
            this.stepNumber = this.stepNumber + 1;
        } else {
            Toaster.error(response.errorMessage);
        }
        

    }
    public async GetById() {
        const res = await this.addNewRequestService.GetById(this.id);
        if (res.isSuccess) {
            this.EditfileName = res.result.fileName;
            this.EditContent = res.result.content;
        } else {
            const msg = this.$t(res.errorMessage).toString();
            Toaster.error(msg);
        }
}
    //public back() {
    //    this.stepNumber = this.stepNumber - 1;
    //    console.log(this.stepNumber)
    //}
    public async isEditsubmitFile() {
        let model
        if (this.FilePicked) {
            model = {
                id: this.id,
                content: this.model.file[0].Content,
                fileName: this.model.file[0].FileName
            }
        }
        else {
            model = {
                id: this.id,
                content: this.EditContent,
                fileName: this.EditfileName
            }
        }
        
        const response = await this.addNewRequestService.put(model);
        if (response.isSuccess) {
            this.resultID = response.result;
            this.AdvertiserRequestPaymentTransaction();
            this.stepNumber = this.stepNumber + 1;
        } else {
            Toaster.error(response.errorMessage);
        }


    }
    public async mounted() {
        this.id = this.$route.params.id;
        this.fileName = this.$route.params.fileName;
        if (this.id && this.id !== null && this.fileName == null) {
            this.resultID = this.id;
            this.stepNumber = 2;
            this.ispayMode = true;
            this.AdvertiserRequestPaymentTransaction()
        }
        if (this.id && this.id !== null && this.ispayMode == false) {
            this.GetById();
            this.GetFileName();
            this.stepNumber = 1;
            this.isEditMode = true;  
        }
    }
    public async GetFileName() {
        this.fileNameListOfNumbers = this.fileName;
        (this.$refs.fileListOfNumbers as any).value = this.fileName;
    }
    public async AdvertiserRequestPaymentTransaction() {
        const res = await this.addNewRequestService.AdvertiserRequestPaymentTransaction(this.resultID);
        if (res.isSuccess && res.result.amount==0) {
            this.$router.push({ name: 'RequestListDashboard' });
        }
        else if (res.isSuccess && res.result.amount > 0) {
            this.Amount = res.result.amount;
            this.AmountWithoutTax = res.result.amountWithoutTax;
            this.FileName = res.result.fileName;
            this.HyperPayUrl = res.result.hyperPayUrl;
        } else {
            const msg = this.$t(res.errorMessage).toString();
            Toaster.error(msg);
        }
    }
    public async SaveFile() {
        let model = {
            content: this.model.file[0].Content,
            fileName: this.model.file[0].FileName
        }
        const response = await this.addNewRequestService.post(model);
        if (response.isSuccess) {
            this.$router.push({ name: 'RequestListDashboard' });
        } else {
            Toaster.error(response.errorMessage);
        }


    }
    public async isEditSaveFile() {
        let model
        if (this.FilePicked) {
            model = {
                id: this.id,
                content: this.model.file[0].Content,
                fileName: this.model.file[0].FileName
            }
        }
        else {
            model = {
                id: this.id,
                content: this.EditContent,
                fileName: this.EditfileName
            }
        }
        const response = await this.addNewRequestService.put(model);
        if (response.isSuccess) {
            this.$router.push({ name: 'RequestListDashboard' });
        } else {
            Toaster.error(response.errorMessage);
        }


    }
    public get isOptionChooseValid(): boolean {
        return (this.paymentOptions > 0);
    }
  
    public deleteListOfNumbers() {
        this.model.file.forEach((x, i) => {
            if (x.attachmentType === 1) {
                this.model.file.splice(i, 1);
            }
        });
        this.fileNameListOfNumbers = '';
        (this.$refs.fileListOfNumbers as any).value = '';
    }
    public get isValid(): boolean {
        return (
             this.fileNameListOfNumbers !== '' );
    }
    public onPickFile(type: number) {
        this.typeFile = type;
        switch (type) {
            case 1:
                (this.$refs.fileListOfNumbers as any).click();
                break;

        }
    }
    public onFilePicked(event: any) {
        this.FilePicked = true;
        this.model.file ==
            this.model.file.filter((x) => x.attachmentType !== this.typeFile);
        const files: any[] = event.target.files;
        const modelArray: AdvertiserAttachmentModel = {} as AdvertiserAttachmentModel;
        Array.prototype.forEach.call(files, (file, index) => {
            modelArray.FileName = file.name;
            switch (this.typeFile) {
                case 1:
                    this.fileNameListOfNumbers = file.name;
                    break;
            }
            const reader = new FileReader();
            reader.onloadend = (fileData: any) => {
                modelArray.Content = fileData.target.result.split(',')[1];
                modelArray.attachmentType = this.typeFile;
                this.model.file.push(modelArray);
            };
            reader.readAsDataURL(file);
        });
    }


    public async GotoPaymentOption() {
        if (this.paymentOptions.toString() === '1') {
            this.stepNumber = 3;
            this.showPayment = true;
            this.isMada = false;
            const getHyperPayModelResponse = await this.addNewRequestService.GetHyperPayModel
                (this.Amount, this.isMada);
            if (getHyperPayModelResponse.isSuccess) {
                this.hyperPayModel = getHyperPayModelResponse.result;
                this.checkoutId = this.hyperPayModel.id;
                this.shopperResultUrl = this.hyperPayModel.shopperResultUrl;
                await this.addNewRequestService.AddPaymentTransaction
                    (this.resultID, this.checkoutId, this.isMada, null, null);
                const script = document.createElement('script');
                script.setAttribute('src', this.HyperPayUrl + this.checkoutId);
                const container = document.getElementById('hyperPaycontainer');
                container!.appendChild(script);
            }

        } else if (this.paymentOptions.toString() === '2') {
            this.stepNumber = 3;
            this.showPayment = true;
            this.isMada = true;
            const getHyperPayModelResponse = await this.addNewRequestService.GetHyperPayModel(this.Amount, this.isMada);
            if (getHyperPayModelResponse.isSuccess) {
                this.hyperPayModel = getHyperPayModelResponse.result;
                this.checkoutId = this.hyperPayModel.id;
                this.shopperResultUrl = this.hyperPayModel.shopperResultUrl;
                await this.addNewRequestService.AddPaymentTransaction
                    (this.resultID, this.checkoutId, this.isMada, null, null);
                const script = document.createElement('script');
                script.setAttribute('src', this.HyperPayUrl + this.checkoutId);
                const container = document.getElementById('hyperPaycontainer');
                container!.appendChild(script);
            }
        }   
    }

}