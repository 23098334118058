import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Inject } from 'vue-di-container';
import WithRender from './SenderRequestSupport.html';
import { Toaster } from '../../Services/toast';
import { SenderType } from '../../shared/Enums/SenderType';
import UserService from '../../shared/userService/UserService';
import SenderRequestSupportService from './SenderRequestSupportService';
import { SenderSupportDetailsModel } from './SenderSupportDetailsModel';

@WithRender
@Component({
    components: {
    },
    diProvide: [
        SenderRequestSupportService,
        UserService,
    ],
})
export default class SenderRequestSupport extends Vue {
    @Inject(UserService) public userService!: UserService;
    @Inject(SenderRequestSupportService) public senderRequestSupportService!: SenderRequestSupportService;
    @Prop({ type: Boolean, default: false })
    public layoutSup: boolean | any;
    public pagination: any = {};
    @Prop({ type: String, default: '' })
    public senderType: SenderType[] = [];
    public headers: any = [
        { text: this.$t('dashboardPage.requestId'), value: 'id', align: 'center', sortable: false },
        { text: this.$t('shared.providerName'), value: 'providerName', align: 'center', sortable: false },
        { text: this.$t('shared.requestStatus'), value: 'requestStatuss', align: 'center', sortable: false },
        { text: this.$t('shared.requestType'), value: 'requestType', align: 'center', sortable: false },
        { text: this.$t('shared.startDate'), sortable: false, value: 'startDate', align: 'center' },
        { text: this.$t('shared.duration'), sortable: false, value: 'duration', align: 'center' },
        { text: this.$t('shared.createdDate'), sortable: false, value: 'createdDate', align: 'center' },
        { text: this.$t('shared.lastModifiedDate'), sortable: false, value: 'lastModifiedDate', align: 'center' },
        { text: this.$t('shared.changedProperty'), sortable: false, value: 'changedProperty', align: 'center' },
        { text: this.$t('shared.approvalHistory'), sortable: false, value: 'id', align: 'center', width: '100' },
        { text: this.$t('shared.paymentTransactions'), sortable: false, value: 'id', align: 'center', width: '100' },
    ];

    public senderName: string = '';
    public senderSupportDetailslist: SenderSupportDetailsModel[] = [];
    public async created() {
        this.$emit('childinit', this.layoutSup);
    }
    public async search() {
        let model;
        if (localStorage.storSettings) {
            const storSettings = JSON.parse(localStorage.storSettings);
            this.pagination = storSettings.pagination;
            model = storSettings.filter;
            this.senderName = storSettings.filter.senderName;
        } else {
            model = {
                senderName: this.senderName,
            };
        }
        localStorage.removeItem('storSettings');
        const response = await this.senderRequestSupportService.get(model.senderName);
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
        } else {
                this.senderSupportDetailslist = response.result;
        }
    }
    public async mounted() {
        this.search();
    }
    public getSenderRequestApprovalHistory(id: string) {
        const storSettings = {
            pagination: this.pagination,
            filter: {
                senderName: this.senderName,
            },
        };
        localStorage.storSettings = JSON.stringify(storSettings);
        this.$router.push({ name: 'SenderRequestApprovalHistory', params: { id } });
    }
    public getSenderRequestPaymentTransactions(id: string) {
        const storSettings = {
            pagination: this.pagination,
            filter: {
                senderName: this.senderName,
            },
        };
        localStorage.storSettings = JSON.stringify(storSettings);
        this.$router.push({ name: 'SenderRequestPaymentTransactions', params: { id } });
    }
    public get isValid(): boolean {
        return (this.senderName.trim() !== '');
    }
}
