import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'vue-di-container';
import WithRender from './AddProviderRequest.html';
import moment from 'moment';
import AddProviderRequestService from './AddProviderRequestService';
import { Toaster } from '../../Services/toast';
import { AddProviderRequestModel } from './AddProviderRequestModel';
import ValidationService from '../../Services/ValidationService';


@WithRender
@Component({
    components: {
    },
    diProvide: [
        AddProviderRequestService,
    ],
})
export default class AddProviderRequest extends Vue {
    @Inject(AddProviderRequestService) public addProviderRequestService!: AddProviderRequestService;
    @Inject(ValidationService) public validationService!: ValidationService;

    public model: AddProviderRequestModel = {
        name: '',
        crNumber: '',
        email: '',
        accreditationNumber: '',
        taxNumber: '',
        fileContent: '',
        fileName: '',
        username: '',
        useremail: '',
        mobile: '',
        country: '',
        city: '',
        district: '',
        streetName: '',
        buildingNumber: '',
        postalCode: '',
        vatNumber: '',
        additionalNumber: '',
        otherIdentifier: '',
    };
    public username: string = '';
    public email: string = '';
    public mobileNumber: string = '';
    public fileNameDelegationLetter: string = '';
    public get currentYear() {
        return moment().year();
    }

    public isNumberField(val: KeyboardEvent) {
        if (!this.validationService.isNumber(val.key)) {
            val.preventDefault();

        }
    }

    public  isMobileNumberValid() {
        if (!this.model.mobile.startsWith('5')) {
            Toaster.error(this.$t('shared.InvalidMobileNumber').toString());
        }
    }

    public isNumberFieldPaste(ev: any) {
        const paste = (ev.clipboardData).getData('text');
        if (isNaN(paste)) {
            ev.preventDefault();
        }

    }

    public deleteDelegationLetter() {
        this.model.fileName = '';
        this.model.fileContent = '';
        this.fileNameDelegationLetter = '';
        (this.$refs.fileDelegationLetter as any).value = '';
    }


    public onPickFile() {
        (this.$refs.fileDelegationLetter as any).click();
    }

    public onFilePicked(event: any) {
        const files: any[] = event.target.files;
        Array.prototype.forEach.call(files, (file, index) => {
            this.model.fileName = file.name;
            this.fileNameDelegationLetter = file.name;
            const reader = new FileReader();
            reader.onloadend = (fileData: any) => {
                this.model.fileContent = fileData.target.result.split(',')[1];
            };
            reader.readAsDataURL(file);
        });
    }


    public get isValidAddUser(): boolean {
        return (this.username.trim() !== '' &&
            this.email.trim() !== '' &&
            this.validationService.isValidEmail(this.email.trim()) &&
            this.mobileNumber.trim() !== '' &&
            this.mobileNumber.trim().length === 9
        );
    }

    public async addProviderRequest() {
        this.model.name.trim();
        this.model.crNumber.trim();
        this.model.accreditationNumber.trim();
        this.model.taxNumber.trim();
        this.model.email.trim();
        this.model.username.trim();
        this.model.useremail.trim();
        this.model.mobile.trim();
        this.model.country.trim();
        this.model.city.trim();
        this.model.district.trim();
        this.model.streetName.trim();
        this.model.buildingNumber.trim();
        this.model.postalCode.trim();
        this.model.vatNumber.trim();
        this.model.additionalNumber.trim();
        this.model.otherIdentifier.trim();
        const response = await this.addProviderRequestService.post(this.model);
        if (response.isSuccess) {
            Toaster.success(this.$t('userManagementPage.requestSubmittedSuccessfully').toString());
            this.$router.push({ name: 'Login' });
        } else {
            Toaster.error(response.errorMessage);
        }
    }

    public get isValid(): boolean {
        return (this.model.name.trim() !== '' &&
            this.model.email.trim() !== '' &&
            this.model.accreditationNumber.trim() !== '' &&
            this.model.taxNumber.trim() !== '' &&
            this.model.crNumber.trim() !== '' &&
            this.model.fileContent !== '' &&
            this.model.fileName !== '' &&
            this.model.username.trim() !== '' &&
            this.model.useremail.trim() !== '' &&
            this.model.vatNumber !== '' &&
            this.model.mobile.trim() !== '' &&
            this.model.mobile.startsWith('5') &&
            this.model.mobile.trim().length === 9 &&
            this.model.vatNumber.trim() !== '' &&
            this.model.postalCode.trim() !== '' &&
            this.model.buildingNumber.trim() !== '' &&
            this.model.streetName.trim() !== '' &&
            this.model.district.trim() !== '' &&
            this.model.city.trim() !== '' &&
            this.model.country.trim() !== '' &&
            this.validationService.isValidEmail(this.model.email.trim()) &&
            this.validationService.isValidEmail(this.model.useremail.trim())
        );
    }
}
