import { Service } from 'vue-di-container';
import UserModel from './Model/UserModel';

@Service()
export default class UserRoleService {
    public  GetUserRole(user: UserModel) {
        const roleName = user.details.Role;
        switch (roleName) {
            case 'Platform Admin':
                return 1;
                break;
            case 'Provider User':
                return 2;
                break;
            case 'CITC Admin':
                return 3;
                break;
            case 'CITC User':
                return 4;
                break;
            case 'Provider Admin':
                return 5;
            case 'Support':
                return 6;
                break;
            case 'Finance':
                return 8;
                break;
            case 'Super Admin':
                return 9;
                break;
                case 'Monitor':
                return 10;
                break;
             default :
                return 0;
                break;
        }
    }
}
