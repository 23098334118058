<div class="w-100 h-100">
    <div class="row pt-25">
        <div class="col-12">
            <div class="card card_layout">
                <div class="card-header">
                    <div class="form-row justify-content-between align-items-center w-100">
                        <div class="col-auto">
                            <label class="card_header_text">
                                <i class="fas fa-sync-alt reload"></i>
                                {{$t('RequestListDashboard.orders')}}
                            </label>
                        </div>
                        <div class="col-auto">
                        <button class="btn btn-primary float-right " @click="AddNewRequest"><i class="fa fa-plus mx-2"></i>{{$t('RequestListDashboard.AddNewOrder')}}</button>
                        </div>

                    </div>

                </div>
                <div class="card-body p-3">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="input-group mb-3">
                                <input class="form-control border-right-0 border-left"
                                     v-model="orderNumber"
                                       type="text"
                                       @keypress="search"
                                       :placeholder="$t('RequestListDashboard.orderNumber')" />
                                <div class="input-group-append">
                                    <span class="input-group-text right gray">
                                        <i class="fas fa-search"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <v-select v-model="status"
                                          :items="statusType"
                                          item-text="name"
                                          item-value="id"
                                          v-on:change="search"
                                          :clearable="true"
                                          :label="$t('RequestListDashboard.orderStatus')"
                                          solo>
                                </v-select>
                            </div>
                        </div>
                    </div>

                    <template>
                        <v-data-table 
                                      :headers="headers"
                                      :items="advertiser"
                                      :total-items="advertiserCount"
                                      :pagination.sync="pagination"
                                      @update:pagination="updatePaginate"
                                      :rows-per-page-text="$t('shared.rowsPerPage')"
                                      :rows-per-page-items='[10, 25, 50, 100]'
                                      class="elevation-1 table-striped">
                            <template v-slot:headers="props">
                                <tr>
                                    <th v-for="header in props.headers"
                                        :key="header.text"
                                        :class="['column sortable', pagination.descending ? 'desc' : 'asc', header.value === pagination.sortBy ? 'active' : '']"
                                        @click="changeSort(header.value)" :width="header.width !== ''? header.width : ''">
                                        <v-icon v-if="header.sortable" small>arrow_upward</v-icon>
                                        {{ header.text }}
                                    </th>
                                </tr>
                            </template>
                            <template v-slot:items="props">
                                <tr>
                                    <td class="text-center">{{props.item.orderNumber}}</td>
                                    <td class="text-center">{{GetSenderStatus(props.item.status)}}</td>
                                    <td class="text-center">{{ props.item.createdDate }}</td>
                                    <td class="text-center">
                                        <div class="d-flex justify-content-center align-items-center">

                                            <Progress :transitionDuration="1000" :radius="10" :strokeColor="checkColor(props.item.status)" :strokeWidth="5" :value="getRate(props.item.remainingDays,props.item.totalDays)">
                                                <div class="content"></div>
                                                <template v-slot:footer>
                                                    <b></b>
                                                </template>
                                            </Progress>
                                            <div class="remainingDays">
                                                {{props.item.remainingDays}} {{$t('dashboardPage.of')}} {{props.item.totalDays}} <br /> {{$t('dashboardPage.daysLeft')}}
                                            </div>
                                        </div>
                                    </td>
                                    <td class="text-center btn_menu">
                                        <template>
                                            <v-menu offset-y open-on-hover>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn color="btn-primary"
                                                           v-on="on">
                                                        <div>{{$t('shared.actions')}}</div>
                                                        <i class="fas fa-chevron-down pl-4" style="vertical-align: sub;"></i>
                                                    </v-btn>
                                                </template>
                                                <v-list>
                                                    <v-list-tile v-if=" props.item.status == '1'" @click="Pay(props.item.id)">
                                                        <v-list-tile-title class="white">{{$t('dashboardPage.Pay')}}</v-list-tile-title>
                                                    </v-list-tile>
                                                    <v-list-tile v-if="props.item.status == '1'" @click="Edit(props.item.id, props.item.fileName)">
                                                        <v-list-tile-title class="white">{{$t('dashboardPage.edit')}}</v-list-tile-title>
                                                    </v-list-tile>
                                                    <v-list-tile v-if="props.item.status == '3' && canDownload(props.item.createdDate) " @click="DownloadFile(props.item.id)">
                                                        <v-list-tile-title class="white">{{$t('shared.download')}}</v-list-tile-title>
                                                    </v-list-tile>
                                                    <v-list-tile v-if="props.item.status == '2' || props.item.status == '4' ">
                                                        <v-list-tile-title class="white">{{$t('shared.NoAction')}}</v-list-tile-title>
                                                    </v-list-tile>
                                                </v-list>
                                            </v-menu>
                                        </template>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </template>

                </div>
            </div>

        </div>
    </div>
</div>

