import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import WithRender from './ChangeSenderType.html';
import { Inject } from 'vue-di-container';
import EditSenderService from './ChangeSenderTypeService';
import ValidationService from '../../../Services/ValidationService';
import { AttachmentModel } from '../../AddRequest/AttachmentModel';
import ShortService from '../../Short/ShortService';
import { SenderModel } from '../SenderModel';
import { Toaster } from '../../../Services/toast';
import { DropdownModel } from '../../Provider/ProviderModel';
import moment from 'moment';
import { SenderType } from '../../../shared/Enums/SenderType';
import CITCKeywordsService from '../../CITCKeywords/CITCKeywordsService';
import { CITCKeywordsModel } from '../../CITCKeywords/CITCKeywordsModel';
import { ChangeSenderTypeModel } from './ChangeSenderTypeModel';


@WithRender
@Component({
    components: {
    },
    diProvide: [
        EditSenderService,
        ShortService,
        CITCKeywordsService,
    ],
})
export default class ChangeSenderType extends Vue {
    @Inject(ValidationService) public validationService!: ValidationService;
    @Inject(EditSenderService) public editSenderService!: EditSenderService;
    @Inject(ShortService) public shortService!: ShortService;
    @Inject(CITCKeywordsService) public cITCKeywordsService!: CITCKeywordsService;

    @Prop({ type: Boolean, default: false })
    public layoutSup: boolean | any;

    @Prop({ type: Boolean, default: false })
    public isFromDashboard: boolean | any;

    @Prop({ default: 0 })
    public id!: string;
    public typeFile: number = 0;
    public senderNameLength: number = 11;
    public senderType: SenderType[] = [];
    public keywordsList: CITCKeywordsModel[] = [];
    public isValidSenderName: boolean = true;
    public enterpriseUnifiedNumberDisabled: boolean = true;
    public editSenderDialog: boolean = false;
    public fileNameAdditionalAttachments: string = '';
    public fileNameRegistrationDocument: string = '';
    public fileNameDelegationLetter: string = '';
    public customerTypeList: DropdownModel[] = [
        {
            id: 1,
            name: this.$t('shared.government').toString(),
        },
        {
            id: 2,
            name: this.$t('shared.charity').toString(),
        },
        {
            id: 3,
            name: this.$t('shared.private').toString(),
        },
        {
            id: 4,
            name: this.$t('shared.international').toString(),
        },
    ];
    public senderTypeList: DropdownModel[] = [
        {
            id: SenderType.Advertisement,
            name: this.$t('shared.advertisement').toString(),
        },
        {
            id: SenderType.Service,
            name: this.$t('shared.service').toString(),
        },
        {
            id: SenderType.Awareness,
            name: this.$t('shared.awareness').toString(),
        },
        {
            id: SenderType.Warning,
            name: this.$t('shared.warning').toString(),
        },
    ];
    public model: SenderModel = {
        id: '',
        duration: '',
        senderType: 0,
        newSenderType: 0,
        senderStatus: 0,
        startDate: '',
        senderId: '',
        clientName: '',
        crNumber: '',
        enterpriseUnifiedNumber: '',
        providerName: '',
        customerType: 0,
        representativeEmail: '',
        representativeMobile: '',
        representativeName: '',
        senderRequestAttachments: [],
        senderName: '',
        requestStatus: 0,
        lastRejectionReason: '',
        remainingDays: 0,
        isAlreadyExist: false,
        totalDays: 0,
        comment: '',
        isCriticalSender: false,
        tenantId: '',
    };
    public changeSenderTypeModel: ChangeSenderTypeModel = {
        id: '',
        senderType: 0,
        crNumber: '',
        customerType: 0,
        senderName: ''
    };
    public async created() {
        this.$emit('childinit', this.layoutSup);
        const keywordsListdata = await this.cITCKeywordsService.getAll();
        this.keywordsList = keywordsListdata.result;
    }
    public async mounted() {
        const response = await this.editSenderService.get(this.id);
        if (response.isSuccess) {
            this.model = response.result;
        } else {
            Toaster.error(response.errorMessage);
        }
        this.onchangeCustomerType();
        this.getSenderType();
        if ((this.model.customerType === 2 || this.model.customerType === 3) &&
            this.model.enterpriseUnifiedNumber.length === 0) {
            this.enterpriseUnifiedNumberDisabled = false;
        }
    }

    public isMobileNumberValid() {
        if (!this.model.representativeMobile.startsWith('5')) {
            Toaster.error(this.$t('shared.InvalidMobileNumber').toString());
        }
    }

    public onchangeCustomerType() {
        switch (this.model.customerType) {
            case 1:
                this.senderTypeList = [
                    {
                        id: SenderType.Advertisement,
                        name: this.$t('shared.advertisement').toString(),
                    },
                    {
                        id: SenderType.Service,
                        name: this.$t('shared.service').toString(),
                    },
                    {
                        id: SenderType.Awareness,
                        name: this.$t('shared.awareness').toString(),
                    },
                    {
                        id: SenderType.Warning,
                        name: this.$t('shared.warning').toString(),
                    },
                ];
                this.senderType = [];
                break;
            case 2:
                this.senderTypeList = [
                    {
                        id: SenderType.Advertisement,
                        name: this.$t('shared.advertisement').toString(),
                    },
                    {
                        id: SenderType.Service,
                        name: this.$t('shared.service').toString(),
                    },
                    {
                        id: SenderType.Awareness,
                        name: this.$t('shared.awareness').toString(),
                    },
                ];
                this.senderType = [];
                break;
            case 3:
                this.senderTypeList = [
                    {
                        id: SenderType.Advertisement,
                        name: this.$t('shared.advertisement').toString(),
                    },
                    {
                        id: SenderType.Service,
                        name: this.$t('shared.service').toString(),
                    },
                    {
                        id: SenderType.Awareness,
                        name: this.$t('shared.awareness').toString(),
                    },
                ];
                this.senderType = [];
                break;
            case 4:
                this.senderTypeList = [
                    {
                        id: SenderType.Service,
                        name: this.$t('shared.service').toString(),
                    },
                ];
                this.senderType = [SenderType.Service];
                break;
        }
    }
    public getSenderType() {
        const senderBinary = this.model.senderType;
        if ((senderBinary & 1) === 1) {
            const i = this.senderTypeList.map((item) => item.id).indexOf(1);
            if (i !== undefined) {
                this.senderTypeList.splice(i, 1);
            }
        }
        if ((senderBinary & 2) === 2) {
            const i = this.senderTypeList.map((item) => item.id).indexOf(2);
            if (i !== -1) {
                this.senderTypeList.splice(i, 1);
            }
        }
        if ((senderBinary & 4) === 4) {
            const i = this.senderTypeList.map((item) => item.id).indexOf(4);
            if (i !== -1) {
                this.senderTypeList.splice(i, 1);
            }
        }
        if ((senderBinary & 8) === 8) {
            const i = this.senderTypeList.map((item) => item.id).indexOf(8);
            if (i !== -1) {
                this.senderTypeList.splice(i, 1);
            }
        }
    }
    public getNewSenderType() {
        const senderBinary = this.model.newSenderType;
        if ((senderBinary & 1) === 1) {
            this.senderType.push(1);
        }
        if ((senderBinary & 2) === 2) {
            this.senderType.push(2);
        }
        if ((senderBinary & 4) === 4) {
            this.senderType.push(4);
        }
        if ((senderBinary & 8) === 8) {
            this.senderType.push(8);
        }
    }
    public getCustomerTypeText(typeId: number) {
        switch (typeId) {
            case 1:
                return this.$t('shared.government').toString();
            case 2:
                return this.$t('shared.charity').toString();
            case 3:
                return this.$t('shared.private').toString();
            case 4:
                return this.$t('shared.international').toString();
            default:
                return '-';
        }
    }
    @Watch('senderType', { deep: true })
    public onchangeSenderType() {
        this.isValidSenderName = true;
        if (this.senderType.includes(SenderType.Advertisement)) {
            if (this.model.senderName.length > 8) {
                this.isValidSenderName = false;
                Toaster.error(this.$t('shared.ToAddTypeAdvertisementSendernameShouldBeLessThanOrEqual8').toString());
            }
        } else if (this.senderType.includes(SenderType.Service) && this.model.customerType === 4) {
            if (this.model.senderName.length > 9) {
                this.isValidSenderName = false;
                Toaster.error(this.$t('shared.ToAddTypeServiceWithCustomerTypeInternationalSendernameShouldBeLessThanOrEqual8').toString());
            }
        } else {
            this.senderNameLength = 11;
        }
    }
    public isNumberField(val: KeyboardEvent) {
        if (!this.validationService.isNumber(val.key)) {
            val.preventDefault();
        }
    }
    public isSenderNameField(val: KeyboardEvent) {
        const pattern = /^[-a-zA-Z0-9._& ]*$/;
        if (!pattern.test(val.key)) {
            val.preventDefault();
        }
    }
    public isNumberFieldPaste(ev: any) {
        const paste = (ev.clipboardData).getData('text');
        if (isNaN(paste)) {
            ev.preventDefault();
        }
    }
    public isSenderNameFieldPaste(ev: any) {
        const paste = (ev.clipboardData).getData('text');
        const pattern = /^[-a-zA-Z0-9._& ]*$/;
        if (!pattern.test(paste)) {
            ev.preventDefault();
        }
    }

    public get isValid(): boolean {
        return (
            this.isValidSenderName &&
            this.model.clientName.trim() !== '' &&
            this.model.customerType !== 0 &&
            this.model.crNumber.trim() !== '' &&
            this.model.senderName.trim() !== '' &&
            this.senderType.length !== 0
        );
    }
    public showEditSenderDialog(id: string) {
        this.editSenderDialog = true;
    }
    public hideEditSenderDialog() {
        this.editSenderDialog = false;
    }
    public async save() {
        this.changeSenderTypeModel.senderType = this.senderType.reduce((a, b) => a + b, 0);
        this.changeSenderTypeModel.senderName = this.model.senderName.trim();
        this.changeSenderTypeModel.id = this.model.id;
        this.changeSenderTypeModel.crNumber = this.model.crNumber;
        this.changeSenderTypeModel.customerType = this.model.customerType;
        const response = await this.editSenderService.put(this.changeSenderTypeModel, this.isFromDashboard);
        if (response.isSuccess) {
            Toaster.success(this.$t('shared.editSenderSuccessfully').toString());
            if (this.isFromDashboard) {
                this.$router.push({ name: 'Dashboard' });
            } else {
                this.$router.push({ name: 'SenderInfo', params: { id: this.id.toString() } });
            }
        } else {
            Toaster.error(response.errorMessage);
        }
        this.hideEditSenderDialog();
    }
}
