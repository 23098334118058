<div class="w-100 h-100">
    <div class="row" style="padding-top: 25px;">
        <div class="col-md-12">
            <div class="form-row justify-content-between align-items-baseline">
                <div class="col-auto">
                    <label class="header_text mb-3">
                        {{$t('shared.addRequest')}}
                    </label>
                </div>
            </div>
        </div>
    </div>
    <div class="row justify-content-sm-center">
        <div class="col-sm-8">
            <v-stepper alt-labels :value="stepNumber">
                <v-stepper-header>
                    <v-stepper-step step="1" :class="{'complete': stepNumber > 1}">Client info</v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step step="2" :class="{'complete': stepNumber > 2}">Review</v-stepper-step>
                </v-stepper-header>
            </v-stepper>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="card card_layout">
                <div class="box_conferm" v-if="stepNumber == 3">
                    <img class="pb-4" :src="require('../img/BigCheck.svg')" />
                    <label class="w-100 pb-4">{{$t('shared.requestSubmittedSuccessfully')}}</label>
                    <div class="form-row justify-content-center align-items-center">
                        <div class="col-auto mx-3">
                            <router-link to="/Dashboard" tag="button" class="btn btn-outline-primary px-5 py-2 mx-2">{{$t('shared.requestsListPage')}}</router-link>
                        </div>
                        <div class="col-auto mx-3">
                            <button class="btn btn-primary px-5 py-2" @click="newRequest()">{{$t('shared.newRequest')}}</button>
                        </div>
                    </div>
                </div>
                <div class="card-body card_body_layout" v-show="stepNumber == 1">
                    <div class="row">
                        <div class="col-md-7">
                            <div class="row">
                                <div class="col-md-4 mb-3">
                                    <div class="form-group">
                                        <label>{{$t('shared.clientName')}}</label>
                                        <input class="form-control" v-model="model.clientName" type="text" />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>{{$t('shared.customerType')}}</label>
                                        <v-select v-model="model.customerType"
                                                  :items="customerTypeList"
                                                  :label="$t('shared.select')"
                                                  item-text="name"
                                                  item-value="id"
                                                  solo>
                                        </v-select>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-3" v-if="model.customerType !== 0">
                                    <div class="form-group">
                                        <label v-if="model.customerType === 1">{{$t('shared.budgetNumber')}}</label>
                                        <label v-if="model.customerType === 2">{{$t('shared.licenseNumber')}}</label>
                                        <label v-if="model.customerType === 3">{{$t('shared.commercialRegistrationNumber')}}</label>
                                        <label v-if="model.customerType === 4">{{$t('shared.internationalCommercialRegistrationNumber')}}</label>
                                        <input class="form-control" v-model="model.crNumber" type="text" />
                                    </div>
                                </div>
                                <div class="col-md-4 mb-3" v-if="model.customerType === 2 ||model.customerType === 3 ">
                                    <div class="form-group">
                                        <label>{{$t('shared.enterpriseUnifiedNumber')}}</label>
                                        <input class="form-control" v-model="model.enterpriseUnifiedNumber" type="text" />
                                    </div>
                                </div>
                                <div class="col-md-4 mb-3">
                                    <div class="form-group">
                                        <label>{{$t('shared.representativeName')}}</label>
                                        <input class="form-control" v-model="model.representativeName" type="text" />
                                    </div>
                                </div>
                                <div class="col-md-4 mb-3">
                                    <div class="form-group">
                                        <label>{{$t('shared.phoneNumber')}}</label>
                                        <div v-if="model.customerType ===0 ||model.customerType === 1 || model.customerType === 2 ||model.customerType === 3" class="input-group mb-3">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text gray">
                                                    +966
                                                </span>
                                            </div>
                                            <input class="form-control border-left-0" @change="isMobileNumberValid" @paste="isNumberFieldPaste($event)" maxlength="9" @keypress="isNumberField($event)" v-model="model.representativeMobile" type="text" />
                                        </div>
                                        <div v-if="model.customerType === 4" class="input-group mb-3">
                                            <input class="form-control border-left-0" @paste="isNumberFieldPaste($event)" @keypress="isNumberField($event)" v-model="model.representativeMobile" type="text" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-3">
                                    <div class="form-group">
                                        <label>{{$t('shared.durationYear')}}</label>
                                        <input class="form-control" @paste="isNumberFieldPaste($event)" v-model="model.duration"
                                               @keypress="isNumberField($event)" :disabled="true" type="text" />
                                    </div>
                                </div>
                                <div class="col-md-4 mb-3">
                                    <div class="form-group">
                                        <label>{{$t('shared.representativeEmail')}}</label>
                                        <input class="form-control" v-model="model.representativeEmail" type="text" />
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-8 col-10">
                                            <div class="form-group">
                                                <label>{{$t('shared.comment')}}</label>
                                                <input class="form-control" v-model="model.comment" type="text" />
                                            </div>
                                        </div>
                                        <div class="col-md-8 col-10">
                                            <div class="form-group">
                                                <label>{{$t('shared.attachDelegationLetter')}}</label>
                                                <div class="input-group mb-3">
                                                    <input class="form-control border-right-0 border-left" readonly v-model="fileNameDelegationLetter" type="text" />
                                                    <input type="file" ref="fileDelegationLetter" hidden accept=".png, .jpg, .jpeg, .pdf, .docx" @change="onFilePicked">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text right" style="padding: 2px 2px 0px 2px !important;">
                                                            <i v-if="fileNameDelegationLetter !== ''" class="far fa-trash-alt Icon_delete" @click="deleteDelegationLetter()"></i>
                                                            <button class="btn btn-primary" @click="onPickFile(1)">{{$t('shared.attach')}}</button>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 col-2">
                                            <div class="form-group">
                                                <label class="w-100">&nbsp;</label>
                                                <a class="btn btn-primary" download :href="'../../../delegationletter/'+ getCustomerTypeText(model.customerType) + '.docx'" v-if="model.customerType !== 0">
                                                    {{$t('shared.delegationletter')}}
                                                    <i class="fas fa-file-download ml-2"></i>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="col-md-8 col-10">
                                            <div class="form-group" v-if="model.customerType !== 0">
                                                <label v-if="model.customerType === 1">{{$t('shared.attachbudgetNumberDocument')}} {{$t('shared.optional')}}</label>
                                                <label v-if="model.customerType === 2">{{$t('shared.attachlicenseNumberDocument')}}</label>
                                                <label v-if="model.customerType === 3">{{$t('shared.attachcommercialRegistrationNumberDocument')}} </label>
                                                <label v-if="model.customerType === 4">{{$t('shared.attachinternationalCommercialRegistrationNumberDocument')}}</label>
                                                <div class="input-group mb-3">
                                                    <input class="form-control border-right-0 border-left" readonly v-model="fileNameRegistrationDocument" type="text" />
                                                    <input type="file" ref="fileRegistrationDocument" hidden accept=".png, .jpg, .jpeg, .pdf, .docx" @change="onFilePicked">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text right" style="padding: 2px 2px 0px 2px !important;">
                                                            <i v-if="fileNameRegistrationDocument !== ''" class="far fa-trash-alt Icon_delete" @click="deleteRegistrationDocument()"></i>
                                                            <button class="btn btn-primary" @click="onPickFile(2)">{{$t('shared.attach')}}</button>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-8 col-10">
                                            <div class="form-group">
                                                <label>{{$t('shared.additionalAttachments')}} {{$t('shared.optional')}}</label>
                                                <div class="input-group mb-3">
                                                    <input class="form-control border-right-0 border-left" readonly v-model="fileNameAdditionalAttachments" type="text" />
                                                    <input type="file" ref="fileAdditionalAttachments" hidden accept=".png, .jpg, .jpeg, .pdf, .docx" @change="onFilePicked" multiple>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text right" style="padding: 2px 2px 0px 2px !important;">
                                                            <i v-if="fileNameAdditionalAttachments !== ''" class="far fa-trash-alt Icon_delete" @click="deleteAdditionalAttachments()"></i>
                                                            <button class="btn btn-primary" @click="onPickFile(3)">{{$t('shared.attach')}}</button>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="row">
                                <div class="col-md-3 pt-2 pr-md-0">
                                    <div class="form-group">
                                        <v-checkbox v-model="isCriticalSender"
                                                    :label="$t('shared.criticalSender')"></v-checkbox>
                                    </div>
                                </div>
                                <div class="col-md-4 pr-md-0">
                                    <div class="form-group">
                                        <label>{{$t('shared.sendersList')}}</label>
                                        <v-select v-model="senderType"
                                                  :items="senderTypeList"
                                                  item-text="name"
                                                  item-value="id"
                                                  multiple
                                                  :label="$t('shared.select')"
                                                  solo>
                                        </v-select>
                                    </div>
                                </div>
                                <div class="col-md-5 pl-md-0">
                                    <div class="form-group">
                                        <label>{{$t('shared.sendersName')}}</label>
                                        <div class="input-group mb-3">
                                            <input class="form-control border-right-0 border-left" @paste="isSenderNameFieldPaste($event)" @keypress="isSenderNameField($event)" :maxlength="senderNameLength" v-model="senderName" type="text" />
                                            <div class="input-group-prepend">
                                                <span class="input-group-text right" style="padding: 2px 2px 0px 2px !important;">
                                                    <button class="btn btn-primary" @click="AddSender" :disabled="!isValidSenderName"><i class="fas fa-plus"></i></button>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <ul class="list-group list_Sender scrollbar">
                                        <li class="list-group-item" v-if="model.senderNames.length > 0" v-for="(item, index) in model.senderNames" :key="item">
                                            <lable class="box-name">{{item.senderName}}</lable>
                                            <lable class="box-name" v-if="item.isCriticalSender">{{$t('shared.critical')}}</lable>
                                            <lable class="box-name" v-if="!item.isCriticalSender">{{$t('shared.notCritical')}}</lable>
                                            <i class="far fa-trash-alt Icon_delete" @click="deleteSender(index)"></i>
                                        </li>
                                        <li class="list-group-item" v-if="model.senderNames.length == 0">
                                            {{$t('shared.noSenderNames')}}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body card_body_layout" v-if="stepNumber >= 2">
                    <div class="row">
                        <div class="col-md-3 mb-3">
                            <label class="user_info_header mb-0">{{$t('shared.clientName')}}</label>
                            <h4 class="user_info_txt">{{model.clientName}}</h4>
                        </div>
                        <div class="col-md-3 mb-3">
                            <label class="user_info_header mb-0">{{$t('shared.customerType')}}</label>
                            <h4 class="user_info_txt">{{getCustomerTypeText(model.customerType)}}</h4>
                        </div>
                        <div class="col-md-3 mb-3">
                            <label class="user_info_header mb-0" v-if="model.customerType === 1">{{$t('shared.budgetNumber')}}</label>
                            <label class="user_info_header mb-0" v-if="model.customerType === 2">{{$t('shared.licenseNumber')}}</label>
                            <label class="user_info_header mb-0" v-if="model.customerType === 3">{{$t('shared.commercialRegistrationNumber')}}</label>
                            <label class="user_info_header mb-0" v-if="model.customerType === 4">{{$t('shared.internationalCommercialRegistrationNumber')}}</label>
                            <h4 class="user_info_txt">{{model.crNumber}}</h4>
                        </div>
                        <div class="col-md-3 mb-3">
                            <label class="user_info_header mb-0">{{$t('shared.representativeName')}}</label>
                            <h4 class="user_info_txt">{{model.representativeName}}</h4>
                        </div>
                        <div class="col-md-3 mb-3" v-if="model.customerType === 2 || model.customerType === 3">
                            <label class="user_info_header mb-0">{{$t('shared.enterpriseUnifiedNumber')}}</label>
                            <h4 class="user_info_txt">{{model.enterpriseUnifiedNumber}}</h4>
                        </div>
                        <div class="col-md-3 mb-3">
                            <label class="user_info_header mb-0">{{$t('shared.phoneNumber')}}</label>
                            <h4 class="user_info_txt"><span class="pr-3">+966</span> {{model.representativeMobile}}</h4>
                        </div>
                        <div class="col-md-3 mb-3">
                            <label class="user_info_header mb-0">{{$t('shared.apEmail')}}</label>
                            <h4 class="user_info_txt">{{model.representativeEmail}}</h4>
                        </div>
                        <div class="col-md-3 mb-3">
                            <label class="user_info_header mb-0">{{$t('shared.duration')}}</label>
                            <h4 class="user_info_txt">{{model.duration}} {{$t('shared.year')}}</h4>
                        </div>
                        <div class="col-md-3 mb-3">
                            {{$t('shared.additionalAttachments')}}
                            <label class="user_info_header mb-0">{{$t('shared.comment')}}</label>
                            <h4 class="user_info_txt">{{model.comment}}</h4>
                        </div>
                        <div class="col-md-3 mb-3">
                            <label class="user_info_header">{{$t('shared.attachDelegationLetter')}}</label>
                            <h4 class="user_info_txt">
                                <span class="badge badge-primary"><i class="fas fa-paperclip"></i>{{model.senderRequestAttachments.filter((x) => x.attachmentType === 1)[0].fileName}}</span>
                            </h4>
                        </div>
                        <div class="col-md-3 mb-3" v-if="model.senderRequestAttachments.filter((x) => x.attachmentType === 2).length !== 0">
                            <label v-if="model.customerType === 1">{{$t('shared.attachUnifiedNumberDocument')}} {{$t('shared.optional')}}</label>
                            <label v-if="model.customerType !== 1">{{$t('shared.attachRegistrationDocument')}}</label>
                            <h4 class="user_info_txt">
                                <span class="badge badge-primary"><i class="fas fa-paperclip"></i>{{model.senderRequestAttachments.filter((x) => x.attachmentType === 2)[0].fileName}}</span>
                            </h4>
                        </div>
                        <div class="col-md-3 mb-3" v-if="model.senderRequestAttachments.filter((x) => x.attachmentType === 3).length !== 0">
                            <label class="user_info_header">{{$t('shared.additionalAttachments')}}</label>
                            <h4 class="user_info_txt">
                                <span class="badge badge-primary" v-for="(item, index) in model.senderRequestAttachments.filter((x) => x.attachmentType === 3)"><i class="fas fa-paperclip"></i>{{item.fileName}}</span>
                            </h4>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="form-row justify-content-between align-items-center">
                        <div class="col-auto mx-3">
                            <a class="link_light" @click="reset" v-if="stepNumber == 1">{{$t('shared.reset')}}</a>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-outline-primary px-5 py-2 mx-2" @click="back" v-if="stepNumber > 1">{{$t('shared.back')}}</button>
                            <button class="btn btn-primary px-5 py-2" @click="next" :disabled="!isValidStep" v-if="stepNumber < 2">{{$t('shared.next')}}</button>
                            <button class="btn btn-primary px-5 py-2" v-if="stepNumber >= 2" @click="showsubmitRequestDialog">{{$t('shared.submit')}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="text-xs-center">
        <v-dialog v-model="submitRequestdialog" width="554">
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                    {{$t('shared.addInput')}}
                    <div class="close__dialog">
                        <i class="fas fa-times" @click="hidesubmitRequestDialog"></i>
                    </div>
                </v-card-title>

                <v-card-text class="text-center">
                    <p class="mt-3">
                        {{$t('shared.addsubmitRequestInputText')}}
                    </p>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <div class="form-row justify-content-end align-items-center">
                        <div class="col-auto mx-3">
                            <a class="link_danger" @click="hidesubmitRequestDialog">
                                {{$t('shared.cancel')}}
                            </a>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-danger px-5 py-2" @click="submitRequest">{{$t('shared.Ok')}}</button>
                        </div>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</div>

