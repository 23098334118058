import { AttachmentModel } from './../AddRequest/AttachmentModel';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import ValidationService from '../../Services/ValidationService';
import WithRender from './RenewContractRequest.html';
import { Inject } from 'vue-di-container';
import { Toaster } from '../../Services/toast';
import CITCKeywordsService from '../CITCKeywords/CITCKeywordsService';
import ProviderService from '../Provider/ProviderService';
import UserService from '../../shared/userService/UserService';
import RenewContractRequestService from './RenewContractRequestService';
import { RenewRequestModel, SenderViewModel } from './RenewContractRequestModel';
import EditSenderService from '../Senders/EditSender/EditSenderService';

@WithRender
@Component({
    components: {
    },
    diProvide: [
        RenewContractRequestService,
        CITCKeywordsService,
        ProviderService,
        EditSenderService,
    ],
})
export default class RenewContractRequest extends Vue {
    @Inject(ValidationService) public validationService!: ValidationService;
    @Inject(RenewContractRequestService) public RenewContractRequestService!: RenewContractRequestService;
    @Inject(CITCKeywordsService) public cITCKeywordsService!: CITCKeywordsService;
    @Inject(EditSenderService) public editSenderService!: EditSenderService;
    @Inject(ProviderService) public providerService!: ProviderService;
    @Inject(UserService) public userService!: UserService;
    @Prop({ type: Boolean, default: false })

    @Prop({ default: 0 })
    public id!: string;

    public renewRequestModel: RenewRequestModel = {
        requestId: '',
        expiryDate: '',
        contractAttachment : null,
    };

    public layoutSup: boolean | any;
    public minDate: Date = new Date();
    public stepNumber: number = 1;
    public senderName: string = '';
    public typeFile: number = 0;
    public contractAttachmentName: string = '';
    public senderType: number[] = [];
    public expiryDateFromMenu: boolean = false;
    public submitRequestdialog: boolean = false;
    public senderNameId: number = 0;
    senderNames: SenderViewModel[]=[];
    public selected: SenderViewModel[] = [];
    public selectedIds: string[] = [];

    public async mounted() {
        const response = await this.editSenderService.get(this.id);
        if (response.isSuccess) {
            this.renewRequestModel.requestId = response.result.id;
        } else {
            Toaster.error(response.errorMessage);
        }
        
    }

    public showsubmitRequestDialog(id: string) {
        this.submitRequestdialog = true;
    }

    public hidesubmitRequestDialog() {
        this.submitRequestdialog = false;
    }

    public async submitRequest() {
        const response = await this.RenewContractRequestService.AddExtendRequest(this.renewRequestModel);
        if (response.isSuccess) {
            this.stepNumber = 4;
        } else {
            Toaster.error(response.errorMessage);
        }
        this.hidesubmitRequestDialog();
    }

    public deleteContract() {
        this.renewRequestModel.contractAttachment= null;
        this.contractAttachmentName = '';
        (this.$refs.contractAttachment as any).value = '';
    }

    public onPickFile(type: number) {
        this.typeFile = type;

                (this.$refs.contractAttachment as any).click();
     
    }

    public onFilePicked(event: any) {
        const files: any[] = event.target.files;
        const modelArray: AttachmentModel = {} as AttachmentModel;
        Array.prototype.forEach.call(files, (file, index) => {
            modelArray.fileName = file.name;
            
                    this.contractAttachmentName = file.name;
           
            const reader = new FileReader();
            reader.onloadend = (fileData: any) => {
                modelArray.fileContent = fileData.target.result.split(',')[1];
                modelArray.attachmentType = this.typeFile;
                this.renewRequestModel.contractAttachment= modelArray;
            };
            reader.readAsDataURL(file);
        });
    }

    public get isValid(): boolean {
        
            return (
                this.renewRequestModel.expiryDate !== "" &&
                new Date( this.renewRequestModel.expiryDate) > this.minDate &&
                this.renewRequestModel.contractAttachment!= null && this.renewRequestModel.contractAttachment.attachmentType === 1);
        
       
    }
    
    public reset() {
        this.renewRequestModel = {
            requestId: this.id,
        expiryDate: '',
        contractAttachment : null,
        };        
    }
}
