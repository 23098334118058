import AxiosService from '@/Services/AxiosService';
import LoaderService from '@/Services/LoaderService';
import { Inject, Service } from 'vue-di-container';
@Service()
export default class AdvertiserRequwstsService {
    @Inject(AxiosService) public axiosService!: AxiosService;
    @Inject(LoaderService) public loaderService!: LoaderService;

    public async Get(model: any): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/advertiser-request';
            const result = await this.axiosService.axiosInstance.get(url, {
                params: model,
            });
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
    public async GetFileConten(id: string): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/advertiser-request/'+id+'/result';
            const result = await this.axiosService.axiosInstance.get(url)
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
    public async editRequest(id: string): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/advertiser-request/' + id + '/result';
            const result = await this.axiosService.axiosInstance.get(url)
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }

}

